import * as React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Link as RouterLink } from 'react-router-dom';
import { useRecordContext } from 'react-admin';
import { Typography, Link } from '@material-ui/core';

const sanitizeRestProps: (props: any) => any = ({
	addLabel,
	allowEmpty,
	basePath,
	cellClassName,
	className,
	emptyText,
	formClassName,
	fullWidth,
	headerClassName,
	label,
	linkType,
	link,
	locale,
	record,
	resource,
	sortable,
	sortBy,
	sortByOrder,
	source,
	textAlign,
	translateChoice,
	...props
}) => props;

const LinkedRecordField: React.FC<LinkedRecordFieldProps> = React.memo<LinkedRecordFieldProps>(
	({ className, emptyText, source, reference, ...rest }) => {
		const record = useRecordContext();
		const value = get(record, source!);

		if (value == null && emptyText) {
			return (
				<Typography
					component="span"
					variant="body2"
					className={className}
					{...sanitizeRestProps(rest)}
				>
					{emptyText}
				</Typography>
			);
		}

		return (
			<Link component={RouterLink}
				to={`/${reference}/${value}/show`}
				className={className}
				{...sanitizeRestProps(rest)}
				onClick={(event: any) => event.stopPropagation()}
			>
				{value}
			</Link>
		);
	}
);

LinkedRecordField.defaultProps = {
	addLabel: true,
};

LinkedRecordField.propTypes = {
	addLabel: PropTypes.bool,
	source: PropTypes.string,
	className: PropTypes.string,
	emptyText: PropTypes.string,
};
LinkedRecordField.displayName = 'LinkedRecordField';

export interface LinkedRecordFieldProps {
	addLabel?: boolean;
	reference: string;
	source?: string;
	className?: string;
	emptyText?: string;
}

export default LinkedRecordField;
