import * as React from "react";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Error as ErrorComponent } from 'ra-ui-materialui';

type ErrorInfo = {
	error: Error;
	info: React.ErrorInfo;
};

type ErrorState = {
	error?: ErrorInfo;
};


interface ChildComponentProps<P> extends RouteComponentProps<P> {
	children: any;
	errorTitle?: string | React.ReactElement<any>;
}

class Catch<P> extends React.Component<ChildComponentProps<P>, ErrorState> {
	state: ErrorState = {};

	constructor(props: ChildComponentProps<P>) {
		super(props);
		/**
		 * Reset the error state upon navigation
		 *
		 * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
		 */
		props.history.listen(() => {
			if (this.state.error) {
				this.setState({ error: undefined });
			}
		});
	}

	static getDerivedStateFromError(error: Error) {
		return {
			error: {
				error
			}
		};
	}

	componentDidCatch(error: Error, info: React.ErrorInfo) {
		this.setState({
			error: {
				error,
				info
			}
		});
	}

	render() {
		if (this.state.error) {
			return (
				<ErrorComponent
					error={this.state.error.error}
					errorInfo={this.state.error.info}
					title={this.props.errorTitle as string}
				/>
			);
		} else {
			const { children, errorTitle, history,
				location, match, staticContext, ...rest } = this.props;
			return (
				<>
					{React.Children.map(children, child =>
						React.cloneElement(child, rest))}
				</>
			);
		}
	}
}

const EnhancedCatch = withRouter(Catch);

export default EnhancedCatch;