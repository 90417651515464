import * as _Docker from './docker';
import * as _Nodes from './nodes';
import * as _Templates from './templates';
import * as _Runtime from './runtime';

export type Node = _Nodes.Node;
export type NodeStatus = _Docker.NodeStatus;
export type Parameter = _Templates.Parameter;
export type ParameterType = _Templates.ParameterType;
export type EnvironmentVariable = _Templates.EnvironmentVariable;
export type Choice = _Templates.Choice;
export type Pipeline = _Templates.Pipeline;
export type ParameterSet = _Runtime.ParameterSet;
export type JobStatus = _Runtime.JobStatus;
export type TaskStatus = _Runtime.TaskStatus;
export type Job = _Runtime.PublicJob;
export type Task = _Runtime.PublicTask;

export type Template = _Templates.TaskUnit & _Templates.TaskGroup & {
	templateType: string;
};

export interface RunSpec {
	pipeline: string;
	parameters: ParameterSet;
}

export function formatTemplate(template: _Templates.Task): Template {
	if (_Templates.isTaskUnit(template)) {
		return Object.assign({}, template, {
			mode: "sequential" as _Templates.ExecutionMode,
			tasks: [],
			templateType: "unit"
		});
	} else {
		return Object.assign({}, template, {
			image: "",
			command: [],
			env: [],
			volumes: [],
			secrets: [],
			macAddress: "",
			minCPUs: 1,
			maxCPUs: 1,
			minMemory: 64,
			maxMemory: 64,
			templateType: "group"
		});
	}
}

export function parseTemplate(template: Template): _Templates.Task {
	if (template.templateType === "unit") {
		return {
			id: template.id,
			owner: template.owner,
			name: template.name,
			description: template.description,
			parameters: template.parameters,
			image: template.image,
			command: template.command,
			env: template.env,
			volumes: template.volumes,
			secrets: template.secrets,
			macAddress: template.macAddress || undefined,
			minCPUs: template.minCPUs,
			maxCPUs: template.maxCPUs,
			minMemory: template.minMemory,
			maxMemory: template.maxMemory
		};
	} else {
		return {
			id: template.id,
			owner: template.owner,
			name: template.name,
			description: template.description,
			parameters: template.parameters,
			mode: template.mode,
			tasks: template.tasks
		};
	}
}