import * as React from 'react';
import { Labeled } from 'ra-ui-materialui';
import Typography from '@material-ui/core/Typography';

const StaticField: React.FC<{
	label: string;
	value: string;
}> = ({ label, value }) => (
	<Labeled
		label={label}
		disabled={false}
	>
		<Typography
			component="span"
			variant="body2"
		>
			{value}
		</Typography>
	</Labeled>
);

export default StaticField;