import { useState } from 'react';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Record, useGetOne } from 'ra-core';
import { Create, SimpleForm, TopToolbar, Toolbar } from 'ra-ui-materialui';
import { ListButton, SaveButton } from 'ra-ui-materialui';
import { SelectInput } from 'ra-ui-materialui';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import { Pipeline, Template } from '../model';
import useGetList, { GetListResults } from '../../../providers/useGetList';
import StaticField from '../../../components/StaticField';
import parametersForm from '../ParametersForm';

function getPipelineId(search: string): string | undefined {
	try {
		const parsed = new URLSearchParams(search.slice(1));
		return parsed.get('pipeline') ?? undefined;
	} catch (err) {
	}
}

const JobCreateToolbar: React.FC<{}> = (props) => (
	<Toolbar {...props} >
		<SaveButton
			{...props}
			icon={<PlayArrowIcon />}
			label="Run"
		/>
	</Toolbar>
);

const JobActions: React.FunctionComponent<{
	basePath?: any;
	data?: any;
	resource?: any;
}> = () => (
	<TopToolbar>
		<ListButton basePath='/pipelines' resource='pipelines' />
	</TopToolbar>
);

const PipelineTitle: React.FC = () => (
	<span>Run pipeline</span>
);

function useTemplate(pipeline: Pipeline): Template | null {
	const response = useGetOne('jobs/templates', pipeline.template);
	return (response.loaded && response.data) ?
		response.data as Template :
		null;
}

const JobCreatePage: React.FC<{
	pipelines: GetListResults<Pipeline>;
	initialValue: string | undefined;
}> = (props) => {
	const pipelineOptions = props.pipelines.ids.map(id => ({
		id,
		name: props.pipelines.data[id].name
	}));

	const [pipeline, setPipeline] = useState<Pipeline>(
		props.initialValue && props.pipelines.data[props.initialValue] ?
			props.pipelines.data[props.initialValue] :
			props.pipelines.data[props.pipelines.ids[0]]
	);
	const template = useTemplate(pipeline);
	if (!template)
		return null;

	return (
		<Create
			title={<PipelineTitle />}
			successMessage="Pipeline scheduled for run"
			{...props}
			actions={<JobActions />}
			transform={(record: Record) => ({
				pipeline: record.pipeline,
				parameters: record.parameters || {}
			} as unknown as Record)
			}
		>
			<SimpleForm
				redirect="show"
				variant="standard"
				submitOnEnter={false}
				initialValues={{
					pipeline: pipeline.id,
					parameters: {}
				}}
				toolbar={<JobCreateToolbar />}
			>
				<SelectInput source="pipeline"
					label="Pipeline"
					helperText="The pipeline to be run"
					choices={pipelineOptions}
					parse={(id: string) => {
						setPipeline(props.pipelines.data[id]);
						return id;
					}}
				/>
				<StaticField label=" " value={pipeline.description} />
				{parametersForm('parameters', template)}
			</SimpleForm>
		</Create>
	);
};

const JobCreate: React.FC<any> = (props) => {
	const response = useGetList<Pipeline>("jobs/pipelines");
	if (!response.isComplete())
		return response.value();
	if (response.ids.length === 0)
		return <Redirect to="/jobs/pipelines" />;

	return (
		<JobCreatePage pipelines={response} initialValue={getPipelineId(props.location.search)} {...props} />
	);
};

export default JobCreate;
