import { Application, ServiceMethods, Query, ServiceAddons } from "@feathersjs/feathers";
import {
	DataProvider, GetListParams, GetOneParams, GetListResult,
	GetOneResult, GetManyParams, GetManyResult, GetManyReferenceParams,
	GetManyReferenceResult, CreateParams, CreateResult, UpdateParams,
	UpdateResult, UpdateManyParams, UpdateManyResult, DeleteParams,
	DeleteResult, DeleteManyParams, DeleteManyResult, Record
} from "react-admin";

type Service<RecordType extends Record> = ServiceMethods<RecordType> & ServiceAddons<RecordType>;

export default class FeathersDataProvider implements DataProvider {
	readonly app: Application<any>;

	constructor(app: Application<any>) {
		this.app = app;
	}

	private getService<RecordType extends Record>(resource: string): Service<RecordType> {
		return this.app.service(resource);
	}

	subscribe(resource: string, id: string | undefined, callback: () => void): () => void {
		const service = this.getService(resource);
		const events = id ? ['updated', 'patched', 'removed'] :
			['created', 'updated', 'patched', 'removed'];

		const listeners = events.map(event => {
			const adHocCallback = (record: Record, context: any) => {
				if (!id || record.id === id)
					callback();
			};
			service.on(event, adHocCallback);
			return () => service.off(event, adHocCallback);
		});
		return () => {
			listeners.forEach(listener => listener());
		};
	}

	async getAll(resource: string) {
		const service = this.getService(resource);
		return await service.find({ paginate: false });
	}

	// GET_LIST	
	// Search for resources	
	// Request: { pagination: { page: { int }, perPage: { int } }, sort: { field: { string }, order: { string } }, filter: { Object } }
	// Response: { data: { Record[] }, total: { int } }

	async getList<RecordType extends Record>(resource: string, params: GetListParams): Promise<GetListResult<RecordType>> {
		const service = this.getService(resource);
		const query = getQuery(params);
		const response = await service.find({ query: query });
		return paginatedResponse<RecordType>(response);
	}


	// GET_ONE	
	// Read a single resource, by id
	// Request: { id: { mixed } }
	// Response: { data: { Record } }

	async getOne<RecordType extends Record>(resource: string, params: GetOneParams): Promise<GetOneResult<RecordType>> {
		const service = this.getService<RecordType>(resource);
		const response = await service.get(params.id);
		return { data: response };
	}


	// GET_MANY	
	// Read a list of resource, by ids	
	// Request: { ids: { mixed[] } }
	// Response: { data: { Record[] } }

	async getMany<RecordType extends Record>(resource: string, params: GetManyParams): Promise<GetManyResult<RecordType>> {
		const service = this.getService(resource);
		const query: Query = {
			'id': { $in: params.ids },
			'$limit': params.ids.length
		};
		const response = await service.find({ query: query });
		return { data: paginatedResponse<RecordType>(response).data };
	}

	// GET_MANY_REFERENCE
	// Read a list of resources related to another one
	// Request: { target: { string }, id: { mixed }, pagination: { page: { int } , perPage: { int } }, sort: { field: { string }, order: { string } }, filter: { Object } }
	// Response: { data: { Record[] }, total: { int } }

	async getManyReference<RecordType extends Record>(resource: string, params: GetManyReferenceParams): Promise<GetManyReferenceResult<RecordType>> {
		const service = this.getService(resource);
		const query = getQuery(params);
		if (params.target && params.id) {
			query[params.target] = params.id;
		}
		const response = await service.find({ query: query });
		return paginatedResponse<RecordType>(response);
	}


	// CREATE
	// Create a single resource
	// Request: { data: { Object } }
	// Response: { data: { Record } }

	async create<RecordType extends Record>(resource: string, params: CreateParams): Promise<CreateResult<RecordType>> {
		const service = this.getService<RecordType>(resource);
		const response = (await service.create(params.data)) as RecordType;
		return { data: response};
	}


	// UPDATE
	// Update a single resource	
	// Request: { id: { mixed }, data: { Object }, previousData: { Object } }
	// Response: { data: { Record } }

	async update<RecordType extends Record>(resource: string, params: UpdateParams): Promise<UpdateResult<RecordType>> {
		const service = this.getService<RecordType>(resource);
		const response = await service.patch(params.id, params.data) as RecordType;
		return (
			{ data: response}
		);
	}


	// UPDATE_MANY
	// Update multiple resources
	// Request: { ids: { mixed[] }, data: { Object } }
	// Response: { data: { mixed[] } } The ids which have been updated

	async updateMany(resource: string, params: UpdateManyParams): Promise<UpdateManyResult> {
		const service = this.getService<Record>(resource);
		const response = await Promise.all((params.ids).map(async (id) => {
			const record = await service.update(id, params.data) as Record;
			return record.id;
		}));
		return { data: response };
	}


	// DELETE
	// Delete a single resource	
	// Request: { id: { mixed }, previousData: { Object } }
	// Response: { data: { RecordType | null } } The record that has been deleted(optional)

	async delete<RecordType extends Record>(resource: string, params: DeleteParams): Promise<DeleteResult<RecordType>> {
		const service = this.getService<RecordType>(resource);
		const response = await service.remove(params.id);
		return { data: response as RecordType };
	}

	// DELETE_MANY
	// Delete multiple resources
	// Request: { ids: { mixed[] } }
	// Response: { data: { mixed[] } } The ids of the deleted records(optional)

	async deleteMany(resource: string, params: DeleteManyParams): Promise<DeleteManyResult> {
		const service = this.getService<Record>(resource);
		const response = service.options.multi ?
			await service.remove(null, {
				query: {
					id: {
						$in: params.ids
					}
				}
			}) as Record[] :
			await Promise.all(params.ids.map(id => service.remove(id) as Promise<Record>));
		return { data: response.map(record => record.id) };
	}
}

function getQuery(params: GetListParams): Query {
	const query: Query = params.filter ? params.filter : {};

	if (params.pagination) {
		query.$limit = params.pagination.perPage;
		query.$skip = params.pagination.perPage * (params.pagination.page - 1);
	}
	if (params.sort) {
		const { field, order } = params.sort;
		const fields = field.split(',');
		const orders = order.split(',').map(order => order === 'DESC' ? -1 : 1);
		query.$sort = {};
		fields.forEach((field, index) => {
			query.$sort[field] = orders.length > index ? orders[index] : 1;
		});
	}
	return query;
}

function paginatedResponse<RecordType extends Record>(response: any): GetListResult<RecordType> {
	// support paginated and non paginated services
	const resp: GetListResult<RecordType> = { total: 0, data: [] };
	if (Array.isArray(response)) {
		resp.total = response.length;
		resp.data = response as RecordType[];
	} else if (response.total !== undefined) {
		resp.total = response.total;
		resp.data = response.data;
	} else {
		resp.total = 1;
		resp.data = [response as RecordType];
	}
	return resp;
}
