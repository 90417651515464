import { useCallback } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { setSidebarVisibility, LayoutProps as LayoutComponentProps } from 'react-admin';

import { makeStyles, Theme as MuiTheme, ThemeProvider } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';

import { Notification } from 'ra-ui-materialui';
import { Theme, useTheme } from '../themes';

import Header from './Header';
import Footer from '../components/Footer';
import Catch from '../components/Catch';
import Sidebar from './Sidebar';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		minHeight: '100vh',
		minWidth: 'fit-content',
		width: '100%',
		backgroundColor: theme.palette.background.default,
		overflowY: 'hidden'
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	main: {
		flex: 1,
		flexGrow: 1,
		height: '100vh',
		overflowY: 'scroll',
		padding: theme.spacing(12, 4),
	},
	footer: {
		padding: theme.spacing(2),
	},
}));

export interface LayoutProps {
	screenSize: "desktop" | "tablet" | "mobile";
	openSidebar: () => void;
	closeSidebar: () => void;
	sidebarOpen: boolean;
}

function useSetSidebar(state: boolean) {
	const dispatch = useDispatch();
	return useCallback(() => {
		dispatch(setSidebarVisibility(state));
	}, [dispatch, state]);
}

const Layout: React.FC<LayoutComponentProps> = (props) => {
	const classes = useStyles();
	const openSidebar = useSetSidebar(true);
	const closeSidebar = useSetSidebar(false);
	const sidebarOpen = useSelector((state: any) => state.admin.ui.sidebarOpen as boolean);
	const isTablet = useMediaQuery((theme: MuiTheme) => theme.breakpoints.up('sm'));
	const isDesktop = useMediaQuery((theme: MuiTheme) => theme.breakpoints.up('md'));
	const layout: LayoutProps = {
		screenSize: isDesktop ? "desktop" : isTablet ? "tablet" : "mobile",
		openSidebar: openSidebar,
		closeSidebar: closeSidebar,
		sidebarOpen: sidebarOpen
	};

	return (
		<div className={classes.root}>
			<Catch errorTitle={props.title}>
				<CssBaseline />
				<AppBar
					position="fixed"
					color="secondary"
					className={clsx(classes.appBar, {
						[classes.appBarShift]: layout.sidebarOpen,
					})}
				>
					<Header {...layout} />
				</AppBar>
				<Sidebar {...layout} />
				<main id="main" className={classes.main}>
					{props.children}
					<Notification />
					<footer className={classes.footer}>
						<Footer />
					</footer>
				</main>
			</Catch>
		</div>
	);
};

const ThemedLayout: React.FC<LayoutComponentProps> = props => {
	const theme = useTheme();
	return (
		<ThemeProvider theme={theme}>
			<Layout {...props} />
		</ThemeProvider>
	);
};

export default ThemedLayout;
