import * as React from 'react';
import { Record } from 'ra-core';
import { Show, TabbedShowLayout, Tab } from 'ra-ui-materialui';
import PropertyList from '../../components/PropertyList';
import { EmbeddedScanList } from './ScanList';

const TaskShow: React.FunctionComponent<{
	record?: Record;
	permissions?: string[];
}> = (props) => (
	<Show
		{...props}
	>
		<TabbedShowLayout>
			<Tab label="Properties">
				<PropertyList />
			</Tab>
			<Tab label="Scans">
				<EmbeddedScanList {...props} target="task" hideTask />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default TaskShow;
