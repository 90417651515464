import { useState } from 'react';
import * as React from 'react';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';

import SidebarButton from '../components/SidebarButton';
import MainMenu from '../menu/MainMenu';
import { Theme } from '../themes';
import { LayoutProps } from './Layout';

const useStyles = makeStyles((theme: Theme) => ({
	drawer: {
		width: theme.sidebar.width,
		height: '100vh',
		flexShrink: 0,
		whiteSpace: 'nowrap',
		overflowX: 'hidden',
	},
	drawerOpen: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.sidebar.closedWidth
	},
	drawerPaper: {
		flexShrink: 0,
		height: '100vh',
		overflowX: 'hidden',
		borderRight: 'none',
		marginTop: 0,
		position: 'inherit'
	},
	toolbar: {
		display: 'flex',
		padding: theme.spacing(1, 2),
		// necessary for content to be below app bar
		...theme.mixins.toolbar
	},
}));

const Sidebar: React.FC<LayoutProps> = (props) => {
	const classes = useStyles();

	const { screenSize, openSidebar, closeSidebar, sidebarOpen } = props;
	const variant = screenSize === "mobile" ? "temporary" : "persistent";
	const userMenu = screenSize === "mobile";
	const open = screenSize === "mobile" ? sidebarOpen : true;
	const [isTempOpen, setTempOpen] = useState(false);

	const onMouseEnter = () => {
		if (!sidebarOpen) {
			setTempOpen(true);
			openSidebar();
		}
	};

	const onMouseLeave = () => {
		if (isTempOpen) {
			setTempOpen(false);
			closeSidebar();
		}
	};

	const autoClose = screenSize === "desktop" ? onMouseLeave : closeSidebar;

	return (
		<SwipeableDrawer
			variant={variant}
			open={open}
			onOpen={openSidebar}
			onClose={closeSidebar}
			className={clsx(classes.drawer, {
				[classes.drawerOpen]: sidebarOpen,
				[classes.drawerClose]: !sidebarOpen,
			})}
			classes={{
				paper: clsx({
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				}),
			}}
			PaperProps={{ className: classes.drawerPaper }}
		>
			<Divider />
			<List disablePadding
				onClick={autoClose}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				<SidebarButton sidebar={true} {...props} />
				<Divider />
				<MainMenu
					userMenu={userMenu}
					condensed={!sidebarOpen}
				/>
			</List>
		</SwipeableDrawer>
	);
};

export default Sidebar;
