import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Record, useRecordContext } from 'ra-core';
import { List, Responsive, SimpleList, Datagrid, SingleFieldList } from 'ra-ui-materialui';
import Chip from '@material-ui/core/Chip';
import { EditButton, TextField, EmailField, ArrayField } from 'ra-ui-materialui';

import AvatarField from './AvatarField';
import Conditional from '../../components/Conditional';

import useAutoRefresh from '../../providers/useAutoRefresh';

const useStyles = makeStyles(
    {
        chip: { margin: 4, cursor: 'inherit' },
    },
    { name: 'RaChipField' }
);


export const RoleChip: React.FC = (props) => {
	const classes = useStyles(props);
	const text = useRecordContext() as unknown as string;

    if (!text)
		return null;

    return (
        <Chip
            className={classes.chip}
            label={text}
        />
    );
};


const UserList: React.FunctionComponent = (props) => {
	useAutoRefresh('auth/users');
	return (
		<List
			title="Users"
			bulkActionButtons={false}
			{...props}
		>
			<Responsive
				small={
					<SimpleList
						primaryText={(record: Record) => record.name}
						tertiaryText={(record: Record) => record.email}
						linkType="show"
					/>
				}
				medium={
					<Datagrid
						rowClick="show" >
						<AvatarField />
						<TextField source="name" />
						<EmailField source="email" />
						<ArrayField source="roles">
							<SingleFieldList>
								<RoleChip />
							</SingleFieldList>
						</ArrayField>
						<Conditional>
							<EditButton label="" />
						</Conditional>
					</Datagrid>
				}
			/>
		</List>
	);
};

export default UserList;

