import * as React from 'react';
import { usePermissions, useRecordContext } from 'ra-core';
import { LazyLog, ScrollFollow } from 'react-lazylog';
import { Show, SimpleShowLayout, TopToolbar } from 'ra-ui-materialui';
import { ListButton } from 'ra-ui-materialui';
import { makeStyles } from '@material-ui/core/styles';

import BilServerProvider from '../../../providers/BilServerProvider';

import { User } from '../../../providers/User.model';
import { Theme } from '../../../themes';

type LazyLogProps = {
	follow: any;
	onScroll: any;
};

const useStyles = makeStyles((theme: Theme) => ({
	log: {
		height: `calc(100vh - 300px)`,
		minHeight: "100%"
	},
}));

const TaskActions: React.FunctionComponent = () => (
	<TopToolbar>
		<ListButton />
	</TopToolbar>
);

const TaskTitle: React.FunctionComponent = () => {
	const record = useRecordContext();
	return (
		<span>Task {record ? `"${record.name}"` : ''}</span>
	);
}

function renderFunction(url: string, user: User): React.FC<LazyLogProps> {
	const headers = new Headers();
	headers.append('Authorization', 'Bearer ' + user.token);
	return ({ follow, onScroll }) => (
		<LazyLog stream
			url={url}
			fetchOptions={{
				headers: headers
			}}
			follow={follow}
			onScroll={onScroll}
			enableSearch={true}
			selectableLines={true}
		/>
	);
}

const TaskLog: React.FunctionComponent = () => {
	const record = useRecordContext();
	const url = "jobs/logs/" + record!.id;
	const { loaded, permissions } = usePermissions<User>();
	const classes = useStyles();
	return loaded ? (
		<div className={classes.log}>
			<ScrollFollow
				startFollowing={true}
				render={renderFunction(BilServerProvider.logUrl(url), permissions!)}
			/>
		</div>
	) : null;
};

const TaskShow: React.FunctionComponent = (props) => (
	<Show
		title={<TaskTitle />}
		{...props}
		actions={<TaskActions />}
	>
		<SimpleShowLayout>
			<TaskLog />
		</SimpleShowLayout>
	</Show>
);

export default TaskShow;
