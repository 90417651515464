import * as React from 'react';

import { List, Responsive, SimpleList, Datagrid } from 'ra-ui-materialui';
import { TextField, ReferenceManyField } from 'ra-ui-materialui';
import useAutoRefresh from '../../providers/useAutoRefresh';


const TaskTable: React.FC<{}> = (props) => (
	<Responsive {...props}
		small={
			<SimpleList
				primaryText={(record: any) => record.id}
				secondaryText={(record: any) => record.TaskName}
				linkType="show"
			/>
		}
		medium={
			<Datagrid rowClick="show">
				<TextField source="id" />
				<TextField source="TaskName" />
			</Datagrid>
		}
	/>
);

export const EmbeddedTaskList: React.FC<{
	target: string;
}> = ({ target, ...props }) => (
	<ReferenceManyField
		{...props}
		addLabel={false}
		source="id"
		reference="imastore/tasks"
		target={target}
		perPage={10000}
	>
		<TaskTable />
	</ReferenceManyField>
);

const TaskList: React.FC = (props) => {
	useAutoRefresh('imastore/tasks');
	return (
		<List
			bulkActionButtons={false}
			{...props}
		>
			<TaskTable />
		</List>
	);
};

export default TaskList;
