import { TranslationMessages } from './Provider';
import messages from './ra-language-italian';

export default {
	...messages,
	auth: {
		button: {
			login: 'Login',
			googleLogin: 'Login con Google'
		},
		loginPage: {
			message1: 'Utilizza il tuo account Google per accedere',
			message2: 'in modo sicuro ai nostri servizi'
		},
		authPage: {
			message1: "Un'applicazione esterna richiede l'accesso",
			message2: 'ai servizi BIL per tuo conto.',
			block: 'Blocca',
			authorize: 'Autorizza'
		}
	},
	app: {
		labname: "Laboratorio di Brain Imaging"
	},
	settings: {
		theme: {
			name: 'Tema',
			light: 'Chiaro',
			dark: 'Scuro',
			intense: 'Intenso'
		},
		language: {
			locale: 'it',
			name: 'Lingua',
			language: 'Italiano'
		}
	},
	page: {
		dashboard: {
			text: 'Guarda cosa sta succedendo'
		},
		pipelines: {
			run: "Esegui"
		},
		documents: {
			toc: "Indice"
		}
	}
} as TranslationMessages;
