import * as React from 'react';
import clsx from 'clsx'
import { useTranslate } from 'ra-core';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { Theme } from '../themes';


const useStyles = makeStyles((theme: Theme) => ({
	sectionHeader: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	sectionHeaderCondensed: {
		paddingLeft: theme.sidebar.width
	},
	sectionWithoutHeader: {
		paddingTop: theme.spacing(2)
	},
	sectionHeaderPrimary: {
		fontWeight: 500 //theme.typography.fontWeightMedium,
	}
}));

interface MenuSectionHeaderProps {
	condensed?: boolean
	label?: string
}

const MenuSectionHeader: React.FC<MenuSectionHeaderProps> = props => {
	const { condensed, label } = props;
	const classes = useStyles();
	const translate = useTranslate();

	if (!label)
		return (
			<div className={classes.sectionWithoutHeader} />
		);

	const translated = translate(label);
	return (
		<Tooltip
			title={translated}
			enterDelay={500}
		>
			<ListItem className={clsx(classes.sectionHeader, {
				[classes.sectionHeaderCondensed]: condensed
			})}
			>
				<ListItemText
					classes={{
						primary: classes.sectionHeaderPrimary,
					}}
				>
					{condensed ? "      " + translated : translated}
				</ListItemText>
			</ListItem>
		</Tooltip >
	);
};

export default MenuSectionHeader;