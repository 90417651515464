import * as React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslate } from 'ra-core';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { MenuItem as MenuItemDef } from '../Package';

const useStyles = makeStyles((theme: Theme) => ({
	item: {
		paddingTop: 1,
		paddingBottom: 1,
	},
	itemPrimary: {
		fontSize: 'inherit',
	},
	itemIcon: {
		minWidth: 'auto',
		marginRight: theme.spacing(2),
	}
}));

interface MenuItemProps extends MenuItemDef {
	condensed?: boolean
}

const MenuItem: React.FC<MenuItemProps> = props => {
	const { path, label, icon, action } = props;
	const classes = useStyles();
	const translate = useTranslate();
	const translated = translate(label);

	return (
		<Tooltip
			title={translated}
			enterDelay={500}
		>
			<ListItem
				button
				component={Link}
				to={path}
				onClick={action}
				className={clsx(classes.item)}
			>
				{icon ? (
					<ListItemIcon className={classes.itemIcon}>
						{React.createElement(icon)}
					</ListItemIcon>
				) : null}
				<ListItemText
					classes={{
						primary: classes.itemPrimary,
					}}
				>
					{translated}
				</ListItemText>
			</ListItem>
		</Tooltip>
	);
};

export default MenuItem;