import * as React from 'react';
import { usePermissions } from 'ra-core';
import { SimpleShowLayout } from 'ra-ui-materialui';
import { TextField, EmailField, ImageField } from 'ra-ui-materialui';
import { User } from '../../providers/User.model';

const ProfileShow: React.FC<{
	record?: User;
}> = (props) => (
	<SimpleShowLayout {...props}>
		<ImageField source="picture" label="" />
		<TextField source="name" />
		<EmailField source="email" />
	</SimpleShowLayout >
);

const ProfilePage: React.FunctionComponent = (props) => {
	const { loaded, permissions } = usePermissions<User>();
	return loaded ? (
		<ProfileShow record={permissions} />
	) : null;
};

export default ProfilePage;
