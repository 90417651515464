import * as React from 'react';
import { Show, TabbedShowLayout, Tab } from 'ra-ui-materialui';
import { EmbeddedScanList } from './ScanList';
import { EmbeddedSessionList } from './SessionList';
import PropertyList from '../../components/PropertyList';
import { EmbeddedDerivativeList } from './DerivativeList';

const SubjectShow: React.FunctionComponent = (props) => (
	<Show
		{...props}
	>
		<TabbedShowLayout>
			<Tab label="Properties">
				<PropertyList omit={["derivatives"]} />
			</Tab>
			<Tab label="Sessions">
				<EmbeddedSessionList {...props} target="subject" hideSubject />
			</Tab>
			<Tab label="Scans">
				<EmbeddedScanList {...props} target="subject" hideSubject />
			</Tab>
			<Tab label="Anatomical derivatives">
				<EmbeddedDerivativeList {...props} target="original" singleScan />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default SubjectShow;
