import { FC, ChangeEvent, memo } from 'react';
import { TextInput } from 'ra-ui-materialui';
import { InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Form } from 'react-final-form';
import { useTranslate, useListFilterContext, getFieldLabelTranslationArgs, useResourceContext } from 'ra-core';

/**
 * Form and search input for doing a full-text search filter.
 *
 * Triggers a search on change (with debounce).
 *
 * @example
 *
 * const FilterPanel = () => (
 *     <Card>
 *         <CardContent>
 *             <FilterLiveSearch source="title" />
 *         </CardContent>
 *     </Card>
 * );
 */

export interface FilterLiveSearchProps {
	resource?: string;
	source?: string;
	label?: string;
}

type TranslationArguments = [string, any?];

const translationArgs = (resource: string, { source, label }: FilterLiveSearchProps): TranslationArguments => (
	label || source ? getFieldLabelTranslationArgs({
		label: label!,
		resource: resource!,
		source: source!
	}) : ['ra.action.search']
);

const FilterLiveSearch: FC<FilterLiveSearchProps> = props => {
	const { source = 'q', label, ...rest } = props;
	const { filterValues, setFilters } = useListFilterContext();
	const translate = useTranslate();
	const resource = useResourceContext();

	const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target) {
			setFilters({ ...filterValues, [source]: event.target.value }, null);
		} else {
			const { [source]: _, ...filters } = filterValues;
			setFilters(filters, null);
		}
	};

	const onSubmit = () => undefined;

	return (
		<Form onSubmit={onSubmit}>
			{({ handleSubmit }) => (
				<TextInput
					resettable
					helperText={false}
					source={source}
					label={translate(...translationArgs(resource, props))}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<SearchIcon color="disabled" />
							</InputAdornment>
						),
					}}
					onChange={onSearchChange}
					{...rest}
				/>
			)}
		</Form>
	);
};

export default memo(FilterLiveSearch);
