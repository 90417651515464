import * as React from 'react';
import { Show, SimpleShowLayout, SingleFieldList, TopToolbar } from 'ra-ui-materialui';
import { ListButton } from 'ra-ui-materialui';
import { TextField, ArrayField, EmailField, ImageField } from 'ra-ui-materialui';

import ConditionalDeleteButton from '../../components/ConditionalDeleteButton';
//import { DeleteButton } from 'ra-ui-materialui';
import { RegisteredUser } from '../../providers/User.model';
import { RoleChip } from './UserList';
import { useRecordContext } from 'ra-core';

const UserShowLayout: React.FC = (props) => (
	<SimpleShowLayout {...props}>
		<ImageField source="picture" label="" />
		<TextField source="name" />
		<EmailField source="email" />
		<ArrayField source="roles">
			<SingleFieldList>
				<RoleChip />
			</SingleFieldList>
		</ArrayField>
	</SimpleShowLayout >
);

const UserActions: React.FunctionComponent = () => (
	<TopToolbar>
		<ListButton />
		<ConditionalDeleteButton />
	</TopToolbar>
);

const UserTitle: React.FunctionComponent = () => {
	const record = useRecordContext() as RegisteredUser;
	return (
		<span>User {record ? `${record.name}` : ''}</span>
	);
};

const UserShow: React.FunctionComponent = (props) => (
	<Show
		title={<UserTitle />}
		{...props}
		actions={<UserActions />}
	>
		<UserShowLayout />
	</Show>
);

export default UserShow;
