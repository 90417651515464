import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { Show, SimpleShowLayout, TopToolbar } from 'ra-ui-materialui';
import { ReferenceField, ReferenceManyField } from 'ra-ui-materialui';
import { ListButton } from 'ra-ui-materialui';

import StateField from '../StateField';
import ConditionalDeleteButton from '../../../components/ConditionalDeleteButton';
import ParameterFields from '../ParametersField';


import { Job } from '../model';
import { TaskTable } from '../tasks/TaskList';
import SimpleField from '../../../components/SimpleField';
import useAutoRefresh from '../../../providers/useAutoRefresh';


const JobTitle: React.FunctionComponent = () => {
	const record = useRecordContext();
	return (
		<span>Job {record ? `"${record.name}"` : ''}</span>
	);
}

const JobActions: React.FunctionComponent<{
	data?: any;
}> = (props) => (
	props.data ? (
		< TopToolbar >
			<ListButton />
			<ConditionalDeleteButton />
		</TopToolbar >
	) : null
);

const JobShow: React.FunctionComponent<{
	permissions?: string[];
}> = (props) => {
	const record = useRecordContext() as Job | undefined;
	useAutoRefresh('jobs/jobs', record?.id);
	return (
		<Show
			title={<JobTitle />}
			{...props}
			actions={<JobActions />}
		>
			<SimpleShowLayout>
				<SimpleField label="Job name" source="name" />
				<SimpleField label="Job description" source="description" />
				<SimpleField label="Pipeline" source="pipeline" />
				<SimpleField label="Template" source="template" />
				<ParameterFields addLabel={false} label="Parameters" source="parameters" />
				<ReferenceField
					addLabel={false}
					source="owner"
					reference="auth/users"
					link="show"
				>
					<SimpleField label="Launched by" source="name" />
				</ReferenceField>
				<SimpleField label="Started at" source="startedAt" />
				<SimpleField label="Run for" source="lasted" />
				<StateField label="Status" source="status" showValue={true} />
				<ReferenceManyField
					label="Tasks"
					reference="jobs/tasks"
					target="job"
					perPage={10000}
					sort={{ field: "label", order: "ASC" }}
				>
					<TaskTable showNode showStatus />
				</ReferenceManyField>
			</SimpleShowLayout>
		</Show>
	);
};

export default JobShow;
