import * as React from "react";
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import inflection from 'inflection';

import { useTranslate } from 'ra-core';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

interface FieldProps {
	title?: string;
	value: any;
	addLabel?: boolean;
	label?: string;
	className?: string;
}

const sanitizeProps = (props: object): object =>
	omit(props, [
		'addLabel',
		'allowEmpty',
		'basePath',
		'cellClassName',
		'className',
		'emptyText',
		'formClassName',
		'headerClassName',
		'label',
		'linkType',
		'link',
		'locale',
		'record',
		'resource',
		'sortable',
		'sortBy',
		'source',
		'textAlign',
		'translateChoice',
	]);


export const FieldTitle: React.FC<{
	label: string;
}> = ({ label }, ...rest) => {
	const translate = useTranslate();
	return (
		<Typography
			component="span"
			variant="subtitle2"
			{...sanitizeProps(rest)}
		>
			{translate(label, {
				_: inflection.transform(label, ['underscore', 'humanize'])
			})}
			{":\u00a0"}
		</Typography>
	);
};

const Line: React.FC<FieldProps & TypographyProps> =
	({ title, value, label, className, ...rest }) => {
		return (
			<div>
				{title && <FieldTitle {...rest} label={title} />}
				<Typography
					component="span"
					variant="body2"
					className={className}
					{...sanitizeProps(rest)}
				>
					{value != null && typeof value !== 'string'
						? JSON.stringify(value)
						: value || ""}
				</Typography>
			</div>
		);
	};

// wat? TypeScript looses the displayName if we don't set it explicitly
Line.displayName = 'Line';

Line.propTypes = {
	title: PropTypes.string,
	addLabel: PropTypes.bool,
	label: PropTypes.string
};

Line.defaultProps = {
	addLabel: false
};

export default Line;
