import { useDispatch } from 'react-redux';
import { useTranslate, useSetLocale } from 'ra-core';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import i18nProvider from '../../translations/Provider';

import { themes, changeTheme } from '../../themes';

const Settings = () => {
	const translate = useTranslate();
	const setLocale = useSetLocale();
	const dispatch = useDispatch();
	return (
		<>
			<Grid container spacing={3}>
				<Grid item>
					<Card>
						<CardContent>
							<Typography gutterBottom>
								{translate('settings.theme.name')}
							</Typography>
						</CardContent>
						<CardActions>
							<ButtonGroup variant="outlined" color="primary">
								{Object.keys(themes).map(key => (
									<Button
										key={key}
										variant="contained"
										onClick={() => dispatch(changeTheme(key))}
									>
										{translate(`settings.theme.${key}`)}
									</Button>
								))}
							</ButtonGroup>
						</CardActions>
					</Card>
				</Grid>

				<Grid item>
					<Card>
						<CardContent>
							<Typography gutterBottom>
								{translate('settings.language.name')}
							</Typography>
						</CardContent>
						<CardActions>
							<ButtonGroup variant="outlined" color="primary">
								{i18nProvider.availableLocales.map(locale => (
									<Button
										key={locale}
										variant="contained"
										onClick={() => setLocale(locale)}
									>
										{i18nProvider.getLanguage(locale)}
									</Button>
								))}
							</ButtonGroup>
						</CardActions>
					</Card>
				</Grid>
			</Grid>
		</>
	);
};

export default Settings;
