import io from 'socket.io-client';
import feathers from "@feathersjs/client";
import FeathersDataProvider from './FeathersDataProvider';
import FeathersAuthProvider from './FeathersAuthProvider';

const apiUrl = {
	host: process.env.REACT_APP_API_HOST as string,
	path: process.env.REACT_APP_API_PATH as string
};

// Setup app
const app = feathers();

// Setup REST
//const restClient = feathers.rest(apiUrl.host + apiUrl.path);
//app.configure(restClient.fetch(window.fetch));

// Setup socket.io
app.configure(feathers.socketio(io(apiUrl.host, {
	path: apiUrl.path + 'ws/'
})));

// Setup auth
app.configure(feathers.authentication({
	storageKey: 'bilserver'
}));

// Setup providers
const provider = {
	DataProvider: new FeathersDataProvider(app),
	AuthProvider: new FeathersAuthProvider(app, apiUrl.host + apiUrl.path),
	logUrl: (id: string) => apiUrl.host + apiUrl.path + id
};

export default provider;