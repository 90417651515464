import { useCallback } from 'react';
import * as React from 'react';
import GoogleButton from 'react-google-button';
import { useTranslate, useLogout } from 'ra-core';
import useLogin from '../providers/useLogin';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
	button: {
		borderColor: 'rgba(255, 255, 255, 0.7)',
	}
});

export const LoginButton: React.FC = () => {
	const translate = useTranslate();
	const classes = useStyles();
	return (
		<Button className={classes.button}
			variant="outlined"
			color="inherit"
			size="small"
			component={Link}
			to="/login"
		>
			{translate('ra.auth.sign_in')}
		</Button>
	)
};

export const GoogleLoginButton: React.FC = () => {
	const login = useLogin();
	const translate = useTranslate();

	return (
		<GoogleButton
			type="light"
			label={translate('auth.button.googleLogin')}
			onClick={login}
		/>
	);
}

export const LogoutButton: React.FC = () => {
	const translate = useTranslate();
	const classes = useStyles();
	const logout = useLogout();
	const handleClick = useCallback(() => logout(), [logout]);

	return (
		<Button className={classes.button}
			variant="outlined"
			color="inherit"
			size="small"
			onClick={handleClick}
		>
			{translate('ra.auth.logout')}
		</Button>
	);
}

