import * as React from 'react';
import { Record } from 'ra-core';
import { List, Responsive, SimpleList, Datagrid, TopToolbar } from 'ra-ui-materialui';
import { CreateButton, EditButton, DeleteButton } from 'ra-ui-materialui';
import { TextField } from 'ra-ui-materialui';

import Conditional from '../../../components/Conditional';
import ForDevelopers from '../../../components/ForDevelopers';
import useAutoRefresh from '../../../providers/useAutoRefresh';

const ActionToolbar: React.FC<{
	[key: string]: any;
}> = (props) => (
	<TopToolbar>
		<ForDevelopers {...props}>
			<CreateButton undoable={true} />
		</ForDevelopers>
	</TopToolbar>
);

const TemplateList: React.FC = (props) => {
	useAutoRefresh('jobs/templates');
	return (
		<List
			title="Templates"
			bulkActionButtons={false}
			{...props}
			actions={<ActionToolbar />}
		>
			<Responsive
				small={
					<SimpleList
						primaryText={(record: Record) => record.name}
						secondaryText={(record: Record) => record.description}
						linkType="show"
					/>
				}
				medium={
					<Datagrid
						rowClick="show"
					>
						<TextField source="name" />
						<TextField source="description" />
						<Conditional>
							<EditButton label="" />
						</Conditional>
						<Conditional>
							<DeleteButton undoable={true} />
						</Conditional>
					</Datagrid>
				}
			/>
		</List>
	);
};


export default TemplateList;
