import * as React from 'react';

import { Record, Identifier } from 'react-admin';
import { List, Responsive, SimpleList, Datagrid } from 'ra-ui-materialui';
import { TextField, ReferenceManyField, ReferenceArrayField, FunctionField } from 'ra-ui-materialui';
import { FilterList, FilterListItem, SearchInput } from 'ra-ui-materialui';
import { CardContent } from '@material-ui/core';
import LinkedRecordField from '../../components/LinkedRecordField';
import useAutoRefresh from '../../providers/useAutoRefresh';
import TopFilter from '../../components/TopFilter';
import AsideCard from '../../components/AsideCard';
import FilterLiveSearch from '../../components/FilterLiveSearch';

export interface DerivativeListProperties {
	hideSubject?: boolean;
	hideSession?: boolean;
	singleScan?: boolean;
}

const DerivativeTable: React.FC<DerivativeListProperties> =
	({ hideSubject, hideSession, singleScan, ...props }) => (
		<Responsive {...props}
			small={
				<SimpleList
					primaryText={(record: any) => record && (singleScan ?
						record.description ?? record.label ?? record.suffix
						: (record.subject + "-" + record.session + "-" + record.run))}
					secondaryText={(record: any) => record && (singleScan ?
						record.space + "-" + record.hemisphere
						: (record.description ?? record.label ?? record.suffix))}
					linkType="show"
				/>
			}
			medium={
				<Datagrid rowClick={(id: Identifier) => `/imastore/derivatives/${id}/show`}>
					{!singleScan && !hideSubject && <LinkedRecordField source="subject" reference="imastore/subjects" />}
					{!singleScan && !hideSession && <LinkedRecordField source="session" reference="imastore/sessions" />}
					{!singleScan && <TextField source="run" />}
					{!singleScan && <TextField source="type" />}
					{!singleScan && <LinkedRecordField source="task" reference="imastore/tasks" />}
					<TextField source="class" />
					<FunctionField label="Description" render={(record: any) => record.description ?? record.label ?? record.suffix} />
					<TextField source="hemisphere" />
					<TextField source="space" />
				</Datagrid>
			}
		/>
	);

export const EmbeddedDerivativeList: React.FC<DerivativeListProperties & {
	record?: Record;
	source?: string;
	target?: string;
}> = ({ source, target, singleScan, ...props }) => (
	source !== undefined ?
		<ReferenceArrayField
			{...props}
			addLabel={false}
			source={source}
			reference="imastore/derivatives"
			perPage={10000}
			sort={{ field: 'subject,session,run,class,description,space,hemisphere', order: 'ASC' }}
		>
			<DerivativeTable
				singleScan={singleScan}
			/>
		</ReferenceArrayField>
		: target !== undefined ?
			<ReferenceManyField
				{...props}
				addLabel={false}
				source="id"
				reference="imastore/derivatives"
				target={target}
				perPage={10000}
			>
				<DerivativeTable
					singleScan={singleScan}
				/>
			</ReferenceManyField>
			: null
);

const DerivativeTopFilter: React.FC = (props) => (
	<TopFilter {...props}>
		<SearchInput source="id" alwaysOn />
	</TopFilter>
);

const DerivativePanelFilter: React.FC = (props) => (
	<AsideCard>
		<CardContent>
			<FilterLiveSearch source="subject" />
			<FilterList label="Type" icon={null}>
				<FilterListItem label="anat" value={{
					type: "anat"
				}} />
				<FilterListItem label="func" value={{
					type: "func"
				}} />
			</FilterList>
		</CardContent>
	</AsideCard>
);

const DerivativeList: React.FC = (props) => {
	useAutoRefresh('imastore/derivatives');
	return (
		<List
			bulkActionButtons={false}
			filters={<DerivativeTopFilter />}
			aside={<DerivativePanelFilter />}
			sort={{ field: 'subject,session,run,class,description,space,hemisphere', order: 'ASC' }}
			{...props}
		>
			<DerivativeTable />
		</List>
	);
};

export default DerivativeList;
