import * as React from 'react';
import { Record } from 'ra-core';
import { Show, TabbedShowLayout, Tab } from 'ra-ui-materialui';
import PropertyList from '../../components/PropertyList';
import { EmbeddedDerivativeList } from './DerivativeList';
import DownloadButton from './DownloadButton';

export const ScanDownload: React.FunctionComponent<{
	record?: Record;
}> = ({ record, ...props }) => (
	<>
		<DownloadButton record={record} resource="scans" source="image">
			Download image
		</DownloadButton>
		<DownloadButton record={record} resource="scans" source="sidecar">
			Download sidecar
		</DownloadButton>
	</>
);

const ScanShow: React.FunctionComponent<{
	record?: Record;
	permissions?: string[];
}> = (props) => (
	<Show
		{...props}
	>
		<TabbedShowLayout>
			<Tab label="Properties">
				<PropertyList omit={["derivatives"]} />
			</Tab>
			<Tab label="Derivatives">
				<EmbeddedDerivativeList {...props} source="derivatives" singleScan />
			</Tab>
			<Tab label="Download">
				<ScanDownload record={props.record} />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default ScanShow;
