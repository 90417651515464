import * as React from 'react';

import { List, Responsive, SimpleList, Datagrid } from 'ra-ui-materialui';
import { TextField, SelectField, ReferenceManyField } from 'ra-ui-materialui';
import { FilterList, FilterListItem, SearchInput } from 'ra-ui-materialui';
import { CardContent } from '@material-ui/core';

import useAutoRefresh from '../../providers/useAutoRefresh';
import TopFilter from '../../components/TopFilter';
import AsideCard from '../../components/AsideCard';
import FilterLiveSearch from '../../components/FilterLiveSearch';

const genderChoices = [
	{ id: 'M', name: 'male' },
	{ id: 'F', name: 'female' }
];

const SubjectTable: React.FC<{}> = (props) => (
	<Responsive {...props}
		small={
			<SimpleList
				primaryText={(record: any) => record.id}
				secondaryText={(record: any) => record.group}
				tertiaryText={(record: any) => record.gender + " - " + record.birthyear}
				linkType="show"
			/>
		}
		medium={
			<Datagrid rowClick="show"
			>
				<TextField label="Subject" source="id" />
				<TextField source="birthyear" />
				<SelectField source="gender" choices={genderChoices} />
				<TextField source="group" />
			</Datagrid>
		}
	/>
);

export const EmbeddedSubjectList: React.FC<{
	target: string;
}> = ({ target, ...props }) => (
	<ReferenceManyField
		{...props}
		addLabel={false}
		source="id"
		reference="imastore/subjects"
		target={target}
		perPage={10000}
		sort={{ field: 'id', order: 'ASC' }}
	>
		<SubjectTable />
	</ReferenceManyField>
);

const SubjectTopFilter: React.FC = (props) => (
	<TopFilter {...props}>
		<SearchInput source="id" alwaysOn />
	</TopFilter>
);

const SubjectPanelFilter: React.FC = (props) => (
	<AsideCard>
		<CardContent>
			<FilterLiveSearch source="id" label="Subject" />
			<FilterList label="Gender" icon={null} >
				<FilterListItem label="male" value={{
					gender: "M"
				}} />
				<FilterListItem label="female" value={{
					gender: "F"
				}} />
			</FilterList>
			<FilterList label="Group" icon={null} >
				<FilterListItem label="young volunteers" value={{
					group: "young volunteers"
				}} />
			</FilterList>
		</CardContent>
	</AsideCard>
);

const SubjectList: React.FC = (props) => {
	useAutoRefresh('imastore/subjects');

	return (
		<List
			bulkActionButtons={false}
			filters={<SubjectTopFilter />}
			aside={<SubjectPanelFilter />}
			sort={{ field: 'id', order: 'ASC' }}
			{...props}
		>
			<SubjectTable />
		</List>
	);
};

export default SubjectList;
