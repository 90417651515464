import { I18nProvider, TranslationMessages as RaTranslationMessages } from 'ra-core';
import { resolveBrowserLocale } from 'ra-core';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import { PackageManager } from '../Package';
import BilServerProvider from '../providers/BilServerProvider';

import englishMessages from './en';
import italianMessages from './it';

export interface TranslationMessages extends RaTranslationMessages {
	settings: {
		language: {
			locale: string
			name: string
			language: string
		}
	}
}

interface TranslationSetup {
	[key: string]: TranslationMessages
}

class TranslationProvider implements I18nProvider {
	readonly messages: TranslationSetup = {}
	readonly availableLocales: string[]
	readonly defaultLocale: string
	private i18nProvider: I18nProvider = this

	constructor() {
		this.setupTranslations();
		this.availableLocales = Object.keys(this.messages);
		const savedLocale = this.savedLocale();
		this.defaultLocale = savedLocale || this.getDefaultLocale();
		if (!savedLocale)
			this.askLocale();
	}

	translate(key: string, options?: any) {
		return this.i18nProvider.translate(key, options);
	}

	getLocale() {
		return this.i18nProvider.getLocale();
	}

	getLanguage(locale: string) {
		if (locale in this.messages)
			return this.messages[locale].settings.language.language;
		return "";
	}

	async changeLocale(locale: string, options?: any): Promise<void> {
		if (locale in this.messages) {
			localStorage.setItem("locale", locale);
			return this.i18nProvider.changeLocale(locale, options);
		}
	}

	private setupTranslations() {
		[englishMessages, italianMessages].forEach(setup => {
			const locale = setup.settings.language.locale;
			this.messages[locale] = setup;
		});
	}

	setup(packageManager: PackageManager) {
		const translations = packageManager.getTranslatedMessages();
		this.availableLocales.forEach(locale => {
			if (translations[locale]) {
				this.messages[locale].packages = translations[locale].packages;
				this.messages[locale].resources = translations[locale].resources;
				this.messages[locale].pages = translations[locale].pages;
			}
		});
		this.i18nProvider = polyglotI18nProvider(locale => this.messages[locale], this.defaultLocale);
	}

	private savedLocale() {
		const savedLocale = localStorage.getItem("locale");
		if (savedLocale && savedLocale in this.messages) {
			return savedLocale;
		}
	}

	private getDefaultLocale() {
		const browserLocale = resolveBrowserLocale();
		return browserLocale in this.messages ?
			browserLocale : this.availableLocales[0];
	}

	private async askLocale() {
		const locales = await BilServerProvider.DataProvider.getAll("lang") as unknown as string[];
		if (locales.length) {
			this.changeLocale(locales[0]);
		}
	}
}

export default new TranslationProvider();
