import { ReactElement } from 'react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Location } from 'history';

import { useTranslate, useNotify, useRedirect, useDataProvider, DataProviderProxy, useAuthenticated } from 'ra-core';

import { Button } from 'ra-ui-materialui';
import BlockIcon from '@material-ui/icons/Block';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import LoginPageTemplate from './LoginPageTemplate';

type DataProviderFunction = (dataProvider: DataProviderProxy) => Promise<any>;

function blockAccess(otp: string): DataProviderFunction {
	return (dataProvider: DataProviderProxy): Promise<any> => dataProvider.delete('auth/otp', {
		id: otp,
		previousData: { id: otp }
	});
}

function authorizeAccess(otp: string): DataProviderFunction {
	return (dataProvider: DataProviderProxy): Promise<any> => dataProvider.update('auth/otp', {
		id: otp,
		data: {},
		previousData: { id: otp }
	});
}

const AuthButton: React.FC<{
	label: string,
	action: DataProviderFunction,
	redirect: string,
	children?: ReactElement,
	comment: string
}> = (props) => {
	const notify = useNotify();
	const redirect = useRedirect();
	const dataProvider = useDataProvider();

	const action = () => props.action(dataProvider)
		.then(() => {
			// success side effects go here
			redirect(props.redirect);
			notify(props.comment);
		})
		.catch((error: {
			message: string
		}) => {
			// failure side effects go here
			redirect(props.redirect);
			notify(`Error: ${error.message}`, 'warning');
		});

	return (
		<Button label={props.label} onClick={action}>
			{props.children}
		</Button>
	);
}

const AuthorizationPage: React.FC<RouteComponentProps> = ({ location }) => {
	useAuthenticated();
	const loc = location as Location;
	const query = new URLSearchParams(loc.search.slice(1));
	const redirect = query.get('redirect') || "/";
	const otp = loc.pathname.replace(/^\/otp\//, "");
	const translate = useTranslate();
	return (
		<LoginPageTemplate
			header1={translate("auth.authPage.message1")}
			header2={translate("auth.authPage.message2")}
		>
			<AuthButton label={translate("auth.authPage.block")} action={blockAccess(otp)} redirect={redirect} comment="Application has been blocked">
				<BlockIcon />
			</AuthButton>

			<AuthButton label={translate("auth.authPage.authorize")} action={authorizeAccess(otp)} redirect={redirect} comment="Application has been authorized">
				<VerifiedUserIcon />
			</AuthButton>
		</LoginPageTemplate>
	);
}

export default AuthorizationPage;