import { makeStyles } from '@material-ui/core';
import { Filter, FilterProps } from 'ra-ui-materialui';

const useStyles = makeStyles(theme => ({
	form: {
		[theme.breakpoints.up('sm')]: {
			display: 'none'
		},
		[theme.breakpoints.down('sm')]: {
			display: 'block'
		}
	}
}));

const TopFilter: React.FC<FilterProps> = props => {
	const classes = useStyles();
	return <Filter classes={classes} {...props} />;
}

export default TopFilter;