import { ReduxState } from 'ra-core';
import { useSelector } from 'react-redux';
import { createTheme, Theme as MuiTheme, ThemeOptions } from '@material-ui/core/styles';
import { Overrides } from '@material-ui/core/styles/overrides';

import light from './light';
import dark from './dark';
import intense from './intense';
export const themes: Themes = { light, dark, intense };

export interface Themes {
	[key: string]: Theme
}

export interface Theme extends MuiTheme {
	logo: {
		normal: string,
		sidebar: string
	},
	sidebar: {
		width: number,
		closedWidth: number
	},
	toc: {
		width: number
	}
};

export function createAppTheme(
	options: ThemeOptions,
	overrides?: (theme: Theme) => Overrides)
	: Theme {

	if (options.palette) {
		options.palette.secondary = {
			main: "#822433"
		};
	} else {
		options.palette = {
			secondary: {
				main: "#822433"
			}
		};
	}

	const muiTheme: MuiTheme = createTheme(options);
	muiTheme.overrides = {
		MuiIconButton: {
			root: {
				padding: muiTheme.spacing(1),
			},
		},
		MuiAvatar: {
			root: {
				width: 32,
				height: 32,
			},
		}
	};

	const theme: Theme = Object.assign({
		logo: {
			normal: "/logo.svg",
			sidebar: "/logo.svg"
		},
		sidebar: {
			width: 220,
			closedWidth: 55
		},
		toc: {
			width: 175
		}
	}, muiTheme);

	if (overrides)
		theme.overrides = Object.assign(theme.overrides, overrides(theme));

	return theme;
}

interface AppState extends ReduxState {
	theme: Theme;
}

export function useTheme(): Theme {
	return useSelector((state: AppState) => state.theme ?? themes.light);
}

const CHANGE_THEME = 'CHANGE_THEME';

export function changeTheme(theme: string) {
	return {
		type: CHANGE_THEME,
		payload: theme,
	};
}

export function defaultTheme() {
	const theme = localStorage.getItem("theme");
	return {
		theme: (theme && themes[theme]) || themes.light
	};
}

export function themeReducer() {
	return {
		theme: (previousState: Theme = themes.light, reducer: {
			type: any;
			payload: any;
		}) => {
			if (reducer.type === CHANGE_THEME) {
				localStorage.setItem("theme", reducer.payload)
				return themes[reducer.payload];
			}
			return previousState;
		}
	};
}
