import * as React from 'react';

import { Record } from 'ra-core';
import { List, Responsive, SimpleList, Datagrid, TopToolbar } from 'ra-ui-materialui';
import { TextField, NumberField } from 'ra-ui-materialui';

import StateField from '../StateField';
import OwnerField from '../OwnerField';
import ConditionalDeleteButton from '../../../components/ConditionalDeleteButton';

import useAutoRefresh from '../../../providers/useAutoRefresh';

const JobList: React.FC = (props) => {
	useAutoRefresh('jobs/jobs');
	return (
		<List
			title="Scheduled jobs"
			actions={<TopToolbar />}
			bulkActionButtons={false}
			{...props}
		>
			<Responsive
				small={
					<SimpleList
						primaryText={(record: Record) => record.name}
						secondaryText={(record: Record) => record.description}
						tertiaryText={(record: Record) => record.status}
						linkType="show"
					/>
				}
				medium={
					<Datagrid
						rowClick="show"
					>
						<OwnerField label="User" />
						<TextField label="Job" source="name" />
						<TextField label="Started" source="startedAt" sortBy="started" />
						<NumberField label="Run for" source="lasted" sortBy="duration" />
						<NumberField label="CPU" source="cpu" options={{ style: "percent" }} />
						<NumberField label="Memory" source="memory" />
						<StateField label="" source="status" />
						<ConditionalDeleteButton label="" />
					</Datagrid>
				}
			/>
		</List>
	);
};


export default JobList;
