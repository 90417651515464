import { TranslationMessages } from './Provider';
import messages from 'ra-language-english';

export default {
	...messages,
	auth: {
		button: {
			login: 'Login',
			googleLogin: 'Sign in with Google'
		},
		loginPage: {
			message1: 'Use your Google account',
			message2: 'to securely access our services'
		},
		authPage: {
			message1: 'An external application asks to interact',
			message2: 'with the BIL services on your behalf.',
			block: 'Block',
			authorize: 'Authorize'
		}
	},
	app: {
		labname: 'Brain Imaging Lab'
	},
	settings: {
		theme: {
			name: 'Theme',
			light: 'Light',
			dark: 'Dark',
			intense: 'Intense'
		},
		language: {
			locale: 'en',
			name: 'Language',
			language: 'English'
		}
	},
	page: {
		dashboard: {
			text: "See what's happening"
		},
		pipelines: {
			run: "Run"
		},
		documents: {
			toc: "Contents"
		}
	}
} as TranslationMessages;
