import { useEffect } from 'react';
import { useRefresh } from 'ra-core/esm/sideEffect';
import BilServerProvider from './BilServerProvider';

function useAutoRefresh(resource: string, id?: string) {
	const refresh = useRefresh();
	useEffect(() => BilServerProvider.DataProvider.subscribe(
		resource, id, refresh),
		[resource, id, refresh]);
}

export default useAutoRefresh;
