import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Resource as RaResource, ResourceComponentProps, ResourceComponentPropsWithId } from 'react-admin';

import { useTranslate, RouteWithoutLayout } from 'react-admin';
import { NotFound, Title } from 'ra-ui-materialui';

import { User } from './providers/User.model';
import useUser from './providers/useUser';

import Markdown from './components/markdown';

type PageComponent = React.FunctionComponent<RouteComponentProps<any>>;
type IconComponent = React.ComponentType<any>;
type Roles = string | string[] | undefined;

type Translated = {
	[locale: string]: string;
};

type TranslatedResourceName = {
	[locale: string]: {
		name: string;
		fields: {
			[field: string]: string;
		},
		tooltips?: {
			[field: string]: string;
		};
	};
};

type Translations = {
	[locale: string]: {
		packages: {
			[pkg: string]: string;
		};
		resources: {
			[path: string]: {
				name: string;
				label?: string;
				fields: {
					[field: string]: string;
				},
				tooltips?: {
					[field: string]: string;
				};
			};
		};
		pages: {
			[path: string]: {
				name: string;
				label?: string;
				contents?: string;
			};
		};
	};
};

interface PackageItem {
	path: string;
	label?: Translated;
	icon?: IconComponent;
	accessibleTo?: Roles;
	showLayout?: boolean;
	showInPackageMenu?: boolean;
	showInUserMenu?: boolean;
}

interface Resource extends PackageItem {
	name: TranslatedResourceName;
	list?: React.ComponentType<ResourceComponentProps>;
	show?: React.ComponentType<ResourceComponentPropsWithId>;
	edit?: React.ComponentType<ResourceComponentPropsWithId>;
	create?: React.ComponentType<ResourceComponentProps>;
	canEdit?: Roles;
}

interface Page extends PackageItem {
	name: Translated;
	component?: PageComponent;
	contents?: Translated;
}

export interface MenuItem {
	path: string;
	label: string;
	icon?: IconComponent;
	action?: () => void;
}

export interface MenuSection {
	id: string;
	label?: string;
	items: MenuItem[];
}

export type MenuProps = MenuSection[];

function isAccessibleTo(accessibleTo: Roles, user: User | null): boolean {
	const roles = Array.isArray(accessibleTo) ? accessibleTo
		: accessibleTo ? [accessibleTo] : undefined;
	if (roles?.length === 1 && roles[0] === "anonymous") // Only anonymous allowed
		return !user;

	if (!roles) 	// Anonymous access allowed
		return true;
	if (!user) 		// Anonymous access denied
		return false;

	if (user.roles.includes("admin"))
		return true;

	return user.roles.some(r => roles.includes(r));
}

class Package {
	readonly name: string;
	readonly label?: Translated;
	readonly items: PackageItem[] = [];

	constructor(name: string, label?: Translated) {
		this.name = name;
		this.label = label;
	}

	defineResource(resource: Resource) {
		this.items.push(resource);
	}

	definePage(page: Page) {
		this.items.push(page);
	}
}

export class PackageManager {
	readonly packages: Package[] = [];

	definePackage(name: string, label?: Translated): Package {
		const pkg = new Package(name, label);
		this.packages.push(pkg);
		return pkg;
	}

	get items(): PackageItem[] {
		return this.packages.map(pkg => pkg.items)
			.reduce((els, el) => els.concat(el), []);
	}

	get resources(): Resource[] {
		return this.packages.map(pkg => pkg.items.filter(i => isResource(i)))
			.reduce((els, el) => els.concat(el), []) as Resource[];
	}

	get pages(): Page[] {
		return this.packages.map(pkg => pkg.items.filter(i => isPage(i)))
			.reduce((els, el) => els.concat(el), []) as Page[];
	}

	getTranslatedMessages(): Translations {
		const locales = [...this.packages
			.map(pkg => pkg.label ? Object.keys(pkg.label) : []),
		...this.resources
			.map(r => r.label ? Object.keys(r.name).concat(Object.keys(r.label)) : Object.keys(r.name)),
		...this.pages
			.map(r => r.label ? Object.keys(r.name).concat(Object.keys(r.label)) : Object.keys(r.name)),
		]
			.reduce((els, el) => els.concat(el), [])
			.filter((value, index, self) => self.indexOf(value) === index);

		const translations: Translations = {};
		locales.forEach(locale => {
			translations[locale] = {
				packages: {},
				resources: {},
				pages: {}
			};
		});

		this.packages.forEach((pkg: Package) => {
			if (pkg.label) {
				Object.keys(pkg.label).forEach(locale => {
					const label = pkg.label as Translated;
					translations[locale].packages[pkg.name] = label[locale];
				});
			}
		});
		this.resources.forEach((resource: Resource) => {
			Object.keys(resource.name).forEach(locale => {
				translations[locale].resources[resource.path] = Object.assign({}, resource.name[locale]);
				if (resource.label)
					translations[locale].resources[resource.path].label = resource.label[locale];
			});
		});
		this.pages.forEach((page: Page) => {
			Object.keys(page.name).forEach(locale => {
				translations[locale].pages[`p${page.path}`] = {
					name: page.name[locale],
					label: page.label ? page.label[locale] : undefined,
					contents: page.contents ? page.contents[locale] : undefined
				};
			});
		});
		return translations;
	}

	getResources(user: User | null): React.ReactElement[] {
		const getResource = (resource: Resource) => {
			const canSee = isAccessibleTo(resource.accessibleTo, user);
			const canEdit = resource.canEdit ? isAccessibleTo(resource.canEdit, user) : canSee;
			return (
				<RaResource
					name={resource.path}
					key={resource.path}
					options={resource.label ?
						{ label: "resources." + resource.path + ".label" }
						: undefined
					}
					icon={resource.icon}
					list={canSee ? resource.list : undefined}
					show={canSee ? resource.show : undefined}
					edit={canEdit ? resource.edit : undefined}
					create={canEdit ? resource.create : undefined}
				/>
			);
		};

		return this.resources
			.map(r => getResource(r as Resource));
	}

	getPages(): React.ReactElement[] {
		const ProtectedRoute: React.FC<Page> = (page) => {
			const Page: PageComponent = (props) => {
				const translate = useTranslate();
				if (page.component) {
					return <page.component {...props} />;
				} else if (page.contents) {
					return <Markdown>
						{translate(`pages.p${page.path}.contents`)}
					</Markdown>;
				} else {
					return null;
				}
			};

			const StandardPage: PageComponent = (props) => {
				if (page.showLayout === undefined || page.showLayout) {
					return (
						<>
							<Title title={`pages.p${page.path}.name`} />
							<Page {...props} />
						</>
					);
				} else {
					return <Page {...props} />;
				}
			};

			const ProtectedPage: PageComponent = (props) => {
				const user = useUser();
				const translate = useTranslate();
				return isAccessibleTo(page.accessibleTo, user) ? (
					<StandardPage {...props} />
				) : (
						< NotFound title={translate('ra.message.not_found')} />
					);
			};

			if (page.showLayout ?? true)
				return <Route exact
					path={"/" + page.path}
					component={page.accessibleTo ? ProtectedPage : StandardPage}
				/>;
			else
				return <RouteWithoutLayout exact
					path={"/" + page.path}
					component={page.accessibleTo ? ProtectedPage : StandardPage}
				/>;
		};

		return this.pages
			.map(p => ProtectedRoute(p)) as React.ReactElement[];
	}

	getMainMenuItems(user: User | null): MenuProps {
		const packageMainMenuItems = (pkg: Package): MenuSection => {
			const elements = pkg.items
				.filter(r => (r.showInPackageMenu === undefined) ? true : r.showInPackageMenu)
				.map(r => getMenuProps(r, user))
				.filter(r => !!r);
			return {
				id: pkg.name,
				label: pkg.label ? ("packages." + pkg.name) : undefined,
				items: elements as MenuItem[]
			};
		};

		return this.packages
			.map(packageMainMenuItems)
			.filter(section => section.items.length > 0);
	}

	getUserMenuItems(user: User | null): MenuItem[] {
		return this.items
			.filter(it => (it.showInUserMenu === undefined) ? false : it.showInUserMenu)
			.map(r => getMenuProps(r, user))
			.filter(r => !!r) as MenuItem[];
	}
}


function getMenuProps(item: PackageItem, user: User | null): MenuItem | null {
	return (isAccessibleTo(item.accessibleTo, user)) ? {
		path: `/${item.path}`,
		label: (isResource(item) ? "resources." : "pages.p")
			+ item.path + "." + (item.label ? "label" : "name"),
		icon: item.icon
	} : null;
}

function isResource(item: PackageItem): item is Resource {
	const name = (item as Resource).name;
	const keys = Object.keys(name);
	return typeof (name[keys[0]]) !== 'string';
}

function isPage(item: PackageItem): item is Page {
	const name = (item as Page).name;
	const keys = Object.keys(name);
	return typeof (name[keys[0]]) === 'string';
}

export const packageManager = new PackageManager();

function definePackage(name: string, label?: Translated): Package {
	return packageManager.definePackage(name, label);
}

export default definePackage;
