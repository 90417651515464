import { useState } from 'react';
import * as React from 'react';
import { useTranslate } from 'ra-core';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import MuiMenu from '@material-ui/core/Menu';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';

import { User } from '../providers/User.model';
import useUser from '../providers/useUser';
import Menu from './Menu';

import { packageManager } from '../Package';
import { LoginButton } from '../components/AuthButtons';

const useStyles = makeStyles({
	button: {
		borderColor: 'rgba(255, 255, 255, 0.7)',
	},
	iconButtonAvatar: {
		padding: 4,
	}
});

const UserMenu: React.FC<{
	user: User
}> = props => {
	const [anchorEl, setAnchorEl] = useState(null);
	const translate = useTranslate();
	const classes = useStyles();

	const open = Boolean(anchorEl);

	const handleMenu = (event: any) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	return (
		<div>
			<Tooltip title={translate('ra.auth.user_menu')}>
				<IconButton
					aria-label={translate('ra.auth.user_menu')}
					aria-owns={open ? 'menu-appbar' : undefined}
					aria-haspopup={true}
					color="inherit"
					className={classes.iconButtonAvatar}
					onClick={handleMenu}
				>
					<Avatar src={props.user.picture} />
				</IconButton>
			</Tooltip>
			<MuiMenu
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={open}
				onClick={handleClose}
				onClose={handleClose}
			>
				<List disablePadding>
					<Menu condensed={false}
						items={packageManager.getUserMenuItems(props.user)}
						divider={false}
						logout={true}
					/>
				</List>
			</MuiMenu>
		</div>
	);
};

const UserMenuOrLoginButton: React.FC<{
	logout?: React.ReactNode
}> = (props) => {
	const user = useUser();
	return user ? (<UserMenu {...props} user={user} />)
		: (<LoginButton />);
}

export default UserMenuOrLoginButton;