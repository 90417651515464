import * as React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import { useTranslate } from 'ra-core';
import { LayoutProps } from '../layout/Layout';
import Logo from './Logo';

import { Theme } from '../themes';


const useStyles = makeStyles((theme: Theme) => ({
	sidebarHeaderClosed: {
		width: theme.sidebar.closedWidth,
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	sidebarHeaderOpen: {
		width: theme.sidebar.width,
		overflowX: 'hidden',
		transition: theme.transitions.create('position', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginLeft: -theme.spacing(1),
		marginRight: theme.spacing(1),
		[theme.breakpoints.up('sm')]: {
			marginLeft: -theme.spacing(2)
		}
	},
	menuButtonIconClosed: {
		transition: theme.transitions.create(['transform'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		transform: 'rotate(180deg)',
	},
	menuButtonIconOpen: {
		transition: theme.transitions.create(['transform'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		transform: 'rotate(0deg)',
	},
}));

interface SidebarButtonProps extends LayoutProps {
	sidebar: boolean
}

const SidebarButton: React.FC<SidebarButtonProps> = props => {
	const { sidebar, openSidebar, closeSidebar, sidebarOpen } = props;
	const classes = useStyles(props);
	const translate = useTranslate();

	return (
		<Tooltip
			title={translate(
				sidebarOpen
					? 'ra.action.close_menu'
					: 'ra.action.open_menu',
				{
					_: 'Open/Close menu',
				}
			)}
			enterDelay={500}
		>
			<ListItem className={sidebarOpen ?
				classes.sidebarHeaderOpen :
				classes.sidebarHeaderClosed
			}>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={sidebarOpen ? closeSidebar : openSidebar}
					className={classes.menuButton}
				>
					<MenuIcon
						className={sidebarOpen ?
							classes.menuButtonIconOpen :
							classes.menuButtonIconClosed
						}
					/>
				</IconButton>
				{sidebarOpen ?
					<>
						<Logo variant={sidebar ? "sidebar" : "normal"} height={45} />
					</>
					: null}
			</ListItem>
		</Tooltip>
	)
};

export default SidebarButton;

