/* eslint-disable react/no-danger */
import * as React from 'react';
import throttle from 'lodash/throttle';
import clsx from 'clsx';
import { useTranslate } from 'ra-core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { TocItem } from './parse';
import { Theme } from '../../themes';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		top: theme.spacing(12),
		right: 0,
		marginTop: 0,
		width: theme.toc.width,
		flexShrink: 0,
		position: 'fixed',
		height: `calc(100vh - ${theme.spacing(12)}px)`,
		overflowY: 'auto',
		padding: theme.spacing(0, 0, 0, 2),
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},
	contents: {
		marginTop: theme.spacing(0),
		paddingLeft: theme.spacing(1.5),
	},
	ul: {
		padding: 0,
		margin: 0,
		listStyle: 'none',
	},
	item: {
		fontSize: 13,
		padding: theme.spacing(0.5, 0, 0.5, 1),
		borderLeft: '4px solid transparent',
		boxSizing: 'content-box',
		textAlign: 'left',
		'&:hover': {
			borderLeft: `4px solid ${
				theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
				}`,
		},
		'&$active,&:active': {
			borderLeft: `4px solid ${
				theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[800]
				}`,
		},
	},
	secondaryItem: {
		paddingLeft: theme.spacing(2.5),
	},
	active: {},
}));

const offsetTop = 100;

interface Anchor {
	hash: string
	node: HTMLElement | null
}

// TODO: these nodes are mutable sources. Use createMutableSource once it's stable
function getAnchors(headings: TocItem[]) {
	const anchors: Anchor[] = [];

	headings.forEach((item) => {
		anchors.push({
			hash: item.hash,
			node: document.getElementById(item.hash.substr(1)),
		});

		if (item.children && item.children.length > 0) {
			item.children.forEach((subitem) => {
				anchors.push({
					hash: subitem.hash,
					node: document.getElementById(subitem.hash.substr(1)),
				});
			});
		}
	});
	return anchors;
}

function findActiveAnchor(element: HTMLElement | null, anchors: Anchor[]): Anchor | null {
	for (let i = anchors.length - 1; i >= 0; i -= 1) {
		const item = anchors[i];
		if (
			element &&
			item.node &&
			item.node.offsetTop < element.scrollTop + offsetTop
		) {
			return item;
		}
	}
	return null;
}

function useThrottledOnScroll(element: HTMLElement | null, callback: (() => void), delay: number) {
	const throttledCallback = React.useMemo(() => (throttle(callback, delay)), [
		callback,
		delay,
	]);

	React.useEffect(() => {
		element && element.addEventListener('scroll', throttledCallback);
		return () => {
			window.removeEventListener('scroll', throttledCallback);
			throttledCallback.cancel();
		};
	}, [element, throttledCallback]);
}

const Toc: React.FC<{
	items: TocItem[]
	scrollElementId: string
}> = props => {
	const { items, scrollElementId } = props;
	const classes = useStyles();
	const translate = useTranslate();

	const scrollElement = document.getElementById(scrollElementId);

	const anchorsRef = React.useRef([] as Anchor[]);
	React.useEffect(() => {
		anchorsRef.current = getAnchors(items);
	}, [items]);

	const [activeState, setActiveState] = React.useState(null as (Anchor | null));
	const clickedRef = React.useRef(false);
	const unsetClickedRef = React.useRef(null as (NodeJS.Timeout | null));
	const findActiveIndex = React.useCallback(() => {
		// Don't set the active index based on scroll if a link was just clicked
		if (clickedRef.current) {
			return;
		}

		let active: Anchor | null = findActiveAnchor(scrollElement, anchorsRef.current);

		if (activeState !== active) {
			setActiveState(active);
		}
	}, [activeState, scrollElement]);

	// Corresponds to 10 frames at 60 Hz
	useThrottledOnScroll(scrollElement, findActiveIndex, 166);

	const handleClick = (hash: string) => (event: React.MouseEvent) => {
		// Ignore click for new tab/new window behavior
		if (
			event.defaultPrevented ||
			event.button !== 0 || // ignore everything but left-click
			event.metaKey ||
			event.ctrlKey ||
			event.altKey ||
			event.shiftKey
		) {
			return;
		}

		// Used to disable findActiveIndex if the page scrolls due to a click
		clickedRef.current = true;
		unsetClickedRef.current = setTimeout(() => {
			clickedRef.current = false;
		}, 1000);

		if (!activeState || activeState.hash !== hash) {
			const found = anchorsRef.current.filter(anchor => anchor.hash === hash);
			const newAnchor = found[0];
			if (newAnchor.node && scrollElement) {
				scrollElement.scroll({
					top: newAnchor.node.offsetTop - offsetTop,
					behavior: 'smooth'
				});
			}
			setActiveState(newAnchor);
		}
	};

	React.useEffect(
		() => () => {
			if (unsetClickedRef.current)
				clearTimeout(unsetClickedRef.current);
		},
		[],
	);

	const itemLink = (item: TocItem, secondary: boolean = false) => (
		<Link
			display="block"
			color={(activeState && activeState.hash === item.hash) ? 'textPrimary' : 'textSecondary'}
			component="button"
			underline="none"
			onClick={handleClick(item.hash)}
			className={clsx(
				classes.item,
				{ [classes.secondaryItem]: secondary },
				(activeState && activeState.hash === item.hash) ? classes.active : undefined,
			)}
		>
			{item.text}
		</Link>
	);

	return (items.length === 0) ? null : (
		<nav className={classes.root}>
			<React.Fragment>
				<Typography gutterBottom className={classes.contents}>
					{translate('page.documents.toc')}
				</Typography>
				<Typography component="ul" className={classes.ul}>
					{items.map((item) => (
						<li key={item.text}>
							{itemLink(item)}
							{item.children && item.children.length > 0 ? (
								<ul className={classes.ul}>
									{item.children.map((subitem) => (
										<li key={subitem.text}>{itemLink(subitem, true)}</li>
									))}
								</ul>
							) : null}
						</li>
					))}
				</Typography>
			</React.Fragment>
		</nav>
	);
}

const AppTableOfContents: React.FC<{
	items: TocItem[]
	scrollElementId: string
}> = props => (
	props.items.length > 0 ? <Toc {...props} /> : null
);

export default AppTableOfContents;