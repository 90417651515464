import Package from '../../Package';

import HomeIcon from '@material-ui/icons/Home';
import raw from 'raw.macro';
import Autocertificazione from './autocertificazione';

function definePakage() {
	const pkg = Package("home");

	pkg.definePage({
		path: "",
		name: {
			en: "Welcome",
			it: "Benvenuto"
		},
		label: {
			en: "Home",
			it: "Home"
		},
		icon: HomeIcon,
		contents: {
			en: raw('./home.en.md'),
			it: raw('./home.it.md')
		}
	});

	pkg.definePage({
		path: "autocertificazione/:nome/:cognome",
		name: {
			en: "Autocertificazione",
			it: "Autocertificazione"
		},
		label: {
			en: "Autocertificazione",
			it: "Autocertificazione"
		},
		showInPackageMenu: false,
		icon: HomeIcon,
		component: Autocertificazione
	});
}


export default definePakage; 