import * as React from 'react';
import { useTheme } from '../themes';

const Logo: React.FC<{
	variant: "normal" | "sidebar"
	height: number
}> = (props) => {
	const theme = useTheme();
	return (
		<img
			src={props.variant === "normal" ? theme.logo.normal : theme.logo.sidebar}
			style={{ height: props.height }}
			alt=""
		/>
	);
}

export default Logo;
