import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthProvider } from 'ra-core';

type LocationState = { nextPathname?: string } | null | undefined;

export default function useLogin() {
	const authProvider = useAuthProvider();
	const location = useLocation<LocationState>();
	const nextPathName = location.state && location.state.nextPathname;
	const redirectTo = nextPathName || "/";
	return useCallback(async () => {
		const url = await authProvider.login({
			redirectTo
		});
		window.location.replace(url);
	}, [authProvider, redirectTo]);
}