import Typography from '@material-ui/core/Typography';
import { useTranslate } from 'ra-core';

function Copyright() {
	const translate = useTranslate();
	return (
		<Typography variant="body2" color="textSecondary" align="center" >
			Copyright © {translate('app.labname')}
			{' '}
			{new Date().getFullYear()}
		</Typography>
	);
}

export default Copyright; 