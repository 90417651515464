import { Application } from "@feathersjs/feathers";
import { CookieStorage } from 'cookie-storage';
import { User } from './User.model';
import { AuthProvider } from "ra-core";
import { Location, History } from 'history';

type Params = { [key: string]: any; };

export default class FeathersAuthProvider implements AuthProvider {
	readonly app: Application<any>;
	private readonly apiUrl: string;
	private userPromise: Promise<void>;
	private user: User | null = null;
	private cookieStorage = new CookieStorage({
		path: "/",
		expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
		secure: true,
		sameSite: 'Strict'
	});

	constructor(app: Application<any>, apiUrl: string) {
		this.app = app;
		this.apiUrl = apiUrl;

		const accessToken = localStorage.getItem(this.app.authentication.options.storageKey);
		this.userPromise = accessToken ?
			this.tryLogin(accessToken) :
			Promise.resolve();
	}

	subscribe(callback: ((user: User | null) => void)): () => void {
		const event = this.user ? 'logout' : 'login';
		this.app.on(event, () => callback(this.user));
		callback(this.user);
		return (() => this.app.off(event, callback));
	}

	login(params: Params): Promise<any> {
		const redirect = params.redirectTo || "/";
		const url = this.apiUrl + "auth/login?redirect=" + encodeURIComponent(redirect);
		return Promise.resolve(url);
	}

	loginCallback(location: Location, history: History): boolean {
		const key = this.app.authentication.options.locationKey;
		const regex = new RegExp(`(?:&?)${key}=([^&]*)`);
		const match = location.hash.match(regex);
		if (match) {
			localStorage.setItem(this.app.authentication.options.storageKey, match[1]);
			this.cookieStorage.setItem(this.app.authentication.options.storageKey, match[1]);
			this.tryLogin(match[1]);
			const params = new URLSearchParams(location.search.slice(1));
			const redirect = params.get('redirect') || "/";
			this.userPromise.then(() => history.replace(redirect as string));
			return true;
		}
		return false;
	}

	private async tryLogin(accessToken: string) {
		this.userPromise = this.app.authenticate({
			strategy: this.app.authentication.options.jwtStrategy,
			accessToken
		}).then((authResult) => {
			const user: User = {
				id: authResult.authentication.payload.id,
				email: authResult.authentication.payload.email,
				name: authResult.authentication.payload.name,
				locale: authResult.authentication.payload.locale,
				picture: authResult.authentication.payload.picture,
				roles: authResult.authentication.payload.roles,
				token: authResult.authentication.accessToken
			};
			user.token = authResult.accessToken;
			this.user = user;
		}).catch(err => {
			console.log(err);
		});
		return this.userPromise;
	}

	async logout(params: Params): Promise<string> {
		this.userPromise = Promise.resolve();
		this.user = null;
		await this.app.logout();
		return "/";
	}

	async checkAuth(params: Params): Promise<void> {
		await this.userPromise;
		if (!this.user)
			return Promise.reject();
	}

	async checkError(params: { code: number; }): Promise<void> {
		var code = params.code;
		if (code === 401) {
			await this.app.logout();
			return Promise.reject();
		}
		return Promise.resolve();
	}

	async getPermissions(params: Params): Promise<User | null> {
		await this.userPromise;
		return this.user;
	}
}
