import * as React from 'react';
import { usePermissions } from 'react-admin';
import { User } from '../providers/User.model';

function isDeveloper(user?: User): boolean {
	return user?.roles.includes("developer") || false;
}

const ForDevelopers: React.FC<any> = ({ children, ...props }) => {
	const { loaded, permissions } = usePermissions();
	return loaded && isDeveloper(permissions) ?
		React.cloneElement(children, props) : null;
};

export default ForDevelopers;
