import Package from '../../Package';

import Settings from './Settings';
import SettingsIcon from '@material-ui/icons/Settings';

function definePackage() {
	const pkg = Package("settings");

	pkg.definePage({
		path: "settings",
		name: {
			en: "Settings",
			it: "Preferenze"
		},
		icon: SettingsIcon,
		component: Settings,
		showInPackageMenu: false,
		showInUserMenu: true
	});

	return pkg;
}

export default definePackage; 