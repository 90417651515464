import * as React from 'react';
import { Show, SimpleShowLayout, TopToolbar } from 'ra-ui-materialui';
import { ReferenceManyField } from 'ra-ui-materialui';
import { ListButton } from 'ra-ui-materialui';

import StateField from '../StateField';

import { TaskTable } from '../tasks/TaskList';
import SimpleField from '../../../components/SimpleField';
import { useRecordContext } from 'ra-core';


const NodeTitle: React.FunctionComponent = () => {
	const record = useRecordContext();
	return (
		<span>Node {record ? `"${record.name}"` : ''}</span>
	);
}

const NodeActions: React.FunctionComponent<{
	data?: any;
}> = (props) => (
	props.data ? (
		< TopToolbar >
			<ListButton />
		</TopToolbar >
	) : null
);

const NodeShow: React.FunctionComponent = (props) => {
	return (
		<Show
			title={<NodeTitle />}
			{...props}
			actions={<NodeActions />}
		>
			<SimpleShowLayout>
				<SimpleField label="Node name" source="name" />
				<SimpleField label="Role" source="role" />
				<SimpleField label="Number of CPUs" source="cpus" />
				<SimpleField label="Total memory (MB)" source="memory" />
				<SimpleField label="Used CPUs" source="usedCpu" />
				<SimpleField label="Used memory (MB)" source="usedMemory" />
				<StateField label="Status" source="status" showValue={true} />
				<ReferenceManyField
					label="Tasks"
					reference="jobs/tasks"
					target="node"
					perPage={10000}
					sort={{ field: "label", order: "ASC" }}
				>
					<TaskTable showJob showOwner showStatus />
				</ReferenceManyField>
			</SimpleShowLayout>
		</Show>
	);
};

export default NodeShow;
