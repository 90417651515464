import Package from '../../Package';

import PeopleIcon from '@material-ui/icons/People';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import ScannerIcon from '@material-ui/icons/Scanner';
import DetailsIcon from '@material-ui/icons/Details';

import SubjectList from './SubjectList';
import SubjectShow from './SubjectShow';
import SessionList from './SessionList';
import SessionShow from './SessionShow';
import TaskList from './TaskList';
import TaskShow from './TaskShow';
import ScanList from './ScanList';
import ScanShow from './ScanShow';
import DerivativeList from './DerivativeList';
import DerivativeShow from './DerivativeShow';

function definePackage() {

	const pkg = Package("imastore", {
		en: "ImaStore",
		it: "ImaStore"
	});

	pkg.defineResource({
		path: "imastore/subjects",
		name: {
			en: {
				name: "Subjects",
				fields: {}
			},
			it: {
				name: "Soggetti",
				fields: {}
			}
		},
		accessibleTo: "researcher",
		icon: PeopleIcon,
		list: SubjectList,
		show: SubjectShow
	});

	pkg.defineResource({
		path: "imastore/sessions",
		name: {
			en: {
				name: "Sessions",
				fields: {}
			},
			it: {
				name: "Sessioni",
				fields: {}
			}
		},
		accessibleTo: "researcher",
		icon: DetailsIcon,
		list: SessionList,
		show: SessionShow
	});

	pkg.defineResource({
		path: "imastore/tasks",
		name: {
			en: {
				name: "Tasks",
				fields: {}
			},
			it: {
				name: "Compiti",
				fields: {}
			}
		},
		accessibleTo: "researcher",
		icon: FormatListNumberedIcon,
		list: TaskList,
		show: TaskShow
	});

	pkg.defineResource({
		path: "imastore/scans",
		name: {
			en: {
				name: "Scans",
				fields: {}
			},
			it: {
				name: "Scansioni",
				fields: {}
			}
		},
		accessibleTo: "researcher",
		icon: ScannerIcon,
		list: ScanList,
		show: ScanShow
	});

	pkg.defineResource({
		path: "imastore/derivatives",
		name: {
			en: {
				name: "Derivatives",
				fields: {}
			},
			it: {
				name: "Derivati",
				fields: {}
			}
		},
		accessibleTo: "researcher",
		icon: ScannerIcon,
		list: DerivativeList,
		show: DerivativeShow
	});

	return pkg;
}

export default definePackage;