import { Theme, createAppTheme } from '.';

const theme: Theme = createAppTheme({
	palette: {
		type: 'light'
	}
}, theme => ({
	MuiDrawer: {
		paper: {
			backgroundColor: 'transparent',
			[theme.breakpoints.only('xs')]: {
				backgroundColor: theme.palette.background.default,
			}
		}
	},
	MuiListItem: {
		button: {
			'&:hover,&:focus': {
				backgroundColor: 'rgba(124, 124, 124, 0.08)',
			},
		}
	},
}));
theme.logo.sidebar = "/logodark.svg";

export default theme;