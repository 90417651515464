import * as React from 'react';
import { createBrowserHistory } from 'history';
import { Admin } from 'react-admin';

import BilServerProvider from './providers/BilServerProvider';
import i18nProvider from './translations/Provider';
import { themeReducer, defaultTheme } from './themes';

import Layout from './layout/Layout';
import { User } from './providers/User.model';
import { packageManager } from './Package';
import definePackages from './packages';

definePackages();
i18nProvider.setup(packageManager);

const history = createBrowserHistory();

const App: React.FC = () => (
	<Admin
		title="Brain Imaging Laboratory"
		layout={Layout}
		history={history}
		i18nProvider={i18nProvider}
		customReducers={themeReducer()}
		initialState={defaultTheme()}
		dataProvider={BilServerProvider.DataProvider}
		authProvider={BilServerProvider.AuthProvider}
		customRoutes={packageManager.getPages()}
		loginPage={false}
	>
		{(permissions: User | null) => packageManager.getResources(permissions)}
	</Admin>
);


export default App;
