import * as React from 'react';
import { Record, useRecordContext } from 'ra-core';
import SimpleField from './SimpleField';

const PropertyList: React.FC<{
	record?: Record,
	omit?: string[];
}> = ({ omit, ...props }) => {
	const record = useRecordContext(props);
	return (
		<>
			{record && Object.keys(record).map(key =>
				(omit && omit.includes(key)) || (
					<SimpleField record={record} key={key} source={key} />
				))}
		</>
	);
};

export default PropertyList;