import Package from '../../Package';

import UserList from './UserList';
import UserShow from './UserShow';
import { UserCreate, UserEdit } from './UserCreateEdit';

import AuthorizationPage from './AuthorizationPage';
import ProfilePage from './Profile';
import LoginPage from './LoginPage';

import AccountCircle from '@material-ui/icons/AccountCircle';
import LoginIcon from '@material-ui/icons/VpnKey';

function definePackage() {
	const pkg = Package("admin", {
		en: "Administration",
		it: "Amministrazione"
	});

	pkg.defineResource({
		path: "auth/users",
		name: {
			en: {
				name: "Registered users",
				fields: {
				}
			},
			it: {
				name: "Utenti registrati",
				fields: {
					"name": "Nome",
					"roles": "Ruoli"
				}
			}
		},
		accessibleTo: "user",
		icon: AccountCircle,
		list: UserList,
		show: UserShow,
		create: UserCreate,
		edit: UserEdit
	});

	pkg.definePage({
		path: "login",
		name: {
			en: "Login",
			it: "Login"
		},
		icon: LoginIcon,
		accessibleTo: "anonymous",
		component: LoginPage,
		showInPackageMenu: false,
		showInUserMenu: true,
		showLayout: false
	});

	pkg.definePage({
		path: "otp/:otp",
		name: {
			en: "Authorization page",
			it: "Autorizzazione"
		},
		accessibleTo: "user",
		component: AuthorizationPage,
		showLayout: false,
		showInPackageMenu: false
	});

	pkg.definePage({
		path: "profile",
		name: {
			en: "My Profile",
			it: "Il mio profilo"
		},
		icon: AccountCircle,
		accessibleTo: ["visitor", "user"],
		component: ProfilePage,
		showInPackageMenu: false,
		showInUserMenu: true
	});

	return pkg;
}

export default definePackage; 