import * as React from 'react';
import get from 'lodash/get';
import { useRecordContext } from 'react-admin';

import { ChipField } from 'ra-ui-materialui';
import CircularProgress from '@material-ui/core/CircularProgress';
import TimerIcon from '@material-ui/icons/Timer';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

type State = "queued" | "active" | "complete" | "failed";

interface StateMap {
	[key: string]: State;
}

interface StateProps {
	variant: "default" | "outlined";
	color: "default" | "primary" | "secondary";
	icon: JSX.Element;
}

const stateMap: StateMap = {
	unknown: "queued",
	waiting: "queued",
	queued: "queued",
	new: "queued",
	allocated: "queued",
	pending: "queued",
	assigned: "queued",
	accepted: "queued",
	preparing: "queued",
	starting: "active",
	ready: "complete",
	running: "active",
	active: "active",
	complete: "complete",
	failed: "failed",
	rejected: "failed",
	remove: "failed",
	orphaned: "failed",
	killed: "failed",
	down: "failed",
	disconnected: "failed"
};

const states = (state: State): StateProps => {
	switch (state) {
		case 'queued':
			return {
				variant: "default",
				color: "default",
				icon: (<TimerIcon />)
			};
		case 'active':
			return {
				variant: "default",
				color: "default",
				icon: (<CircularProgress
					color="inherit"
					size={20}
				/>)
			};
		case 'complete':
			return {
				variant: "default",
				color: "default",
				icon: (<CheckCircleIcon />)
			};
		case 'failed':
			return {
				variant: "default",
				color: "default",
				icon: (<ErrorOutlineIcon />)
			};
	}
};


const StateField: React.FunctionComponent<{
	source: string;
	addLabel?: boolean,
	label?: string,
	showValue?: boolean;
}> = ({ source, showValue }, ...props) => {
	const record = useRecordContext(props);
	if (!record)
		return null;

	const value = get(record, source) as string;
	const state = states(stateMap[value]);

	if (showValue)
		return (
			<ChipField
				{...props}
				record={record}
				source={source}
				{...state}
			/>
		);

	return state.icon;
};

StateField.defaultProps = {
	addLabel: true
};

export default StateField;