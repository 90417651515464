import { useEffect, useState } from 'react';
import { User } from './User.model';
import BilServerProvider from './BilServerProvider';

export default function useUser(): User | null {
	const authProvider = BilServerProvider.AuthProvider;
	const [user, setUser] = useState<User | null>(null);
	useEffect(() => {
		authProvider.getPermissions({}).then(
			(user: User | null) => {
				setUser(user);
			}
		);
	});
	return user;
}