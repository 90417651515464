import * as React from 'react';

import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import { LoadingIndicator } from 'ra-ui-materialui';

import UserMenu from '../menu/UserMenu';
import SidebarButton from '../components/SidebarButton';
import { Theme } from '../themes';
import { LayoutProps } from './Layout';

const useStyles = makeStyles((theme: Theme) => ({
	sidebarHeader: {
		marginLeft: -theme.spacing(2),
	},
	title: {
		paddingLeft: theme.spacing(2),
		fontWeight: 400,
		fontSize: 20,
		letterSpacing: 0.5,
		[theme.breakpoints.up('sm')]: {
			fontSize: 24,
		}
	}
}));

const Header: React.FC<LayoutProps> = (props) => {
	const classes = useStyles();
	const { screenSize } = props;
	const userMenu = screenSize !== "mobile";
	return (
		<Toolbar>
			<Grid container spacing={1} alignItems="center">
				<Grid item
					className={classes.sidebarHeader}
				>
					<List disablePadding>
						<SidebarButton sidebar={false} {...props} />
					</List>
				</Grid>
				< Grid item xs>
					<Typography color="inherit" component="h1"
						className={classes.title}
						id="react-admin-title">
					</Typography>
				</Grid>
				<Grid item>
					<LoadingIndicator />
				</Grid>
				{userMenu ? (
					<Grid item>
						<UserMenu />
					</Grid>
				) : null}
			</Grid>
		</Toolbar >
	);
}

export default Header;