import * as React from 'react';
import { DeleteButton } from 'ra-ui-materialui';
import Conditional from './Conditional';
import { useRecordContext } from 'ra-core';

const ConditionalDeleteButton: React.FC<{
	[key: string]: any;
}> = (props) => {
	const record = useRecordContext();
	return (
		<Conditional>
			<DeleteButton record={record} undoable={true} />
		</Conditional>
	);
};

export default ConditionalDeleteButton;