import * as React from 'react';
import { Record, usePermissions, useRecordContext } from 'react-admin';
import { User } from '../providers/User.model';


function isOwner(record?: Record, user?: User) {
	return user?.roles.includes("admin") || record?.owner === user?.id;
}

const Conditional: React.FC<any> = ({ children, ...props }) => {
	const { loaded, permissions } = usePermissions<User>();
	const record = useRecordContext();
	return loaded && isOwner(record, permissions) ?
		React.cloneElement(children, props) : null;
};

export default Conditional;
