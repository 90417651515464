import * as React from 'react';
import { Record } from 'ra-core';
import { List, Responsive, SimpleList, Datagrid } from 'ra-ui-materialui';
import { TextField, DateField, FunctionField, ReferenceManyField } from 'ra-ui-materialui';
import { FilterList, FilterListItem, SearchInput } from 'ra-ui-materialui';
import { CardContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AssessmentIcon from '@material-ui/icons/Assessment';

import LinkedRecordField from '../../components/LinkedRecordField';
import useAutoRefresh from '../../providers/useAutoRefresh';
import TopFilter from '../../components/TopFilter';
import AsideCard from '../../components/AsideCard';
import FilterLiveSearch from '../../components/FilterLiveSearch';

export interface SessionListProperties {
	hideSubject?: boolean;
}

const ReportButton: React.FC<{
	record?: Record;
}> = ({ record }) =>
		record?.preprocessed ? (
			<Button
				color="primary"
				size="small"
				href={`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}imastore/reports/sub-${record.subject}_ses-${record.id}.html`}
				onClick={e => e.stopPropagation()}
				startIcon={<AssessmentIcon />}
			>
				Report
			</Button>
		) : null;

const SessionTable: React.FC<SessionListProperties> =
	({ hideSubject, ...props }) => (
		<Responsive
			small={
				<SimpleList
					primaryText={(record: Record) => record.id as string}
					secondaryText={(record: Record) => `${record.manufacturer} ${record.model} (${record.institution})`}
					tertiaryText={(record: Record) => record.acquisition_date}
					linkType="show"
				/>
			}
			medium={
				<Datagrid
					rowClick="show"
				>
					{!hideSubject && <LinkedRecordField source="subject" reference="imastore/subjects" />}
					<TextField label="Session" source="id" />
					<DateField source="acquisition_date" />
					<FunctionField label="Scanner"
						render={(record?: Record) => `${record?.manufacturer} ${record?.model} (${record?.institution})`}
					/>
					<TextField source="visual_projection" />
					<FunctionField label="Preprocessing"
						render={(record?: Record) => record?.preprocessed ?
							<ReportButton record={record} />
							: "-"
						}
					/>
				</Datagrid>
			}
		/>
	);

export const EmbeddedSessionList: React.FC<SessionListProperties & {
	target: string;
}> = ({ target, hideSubject, ...props }) => (
	<ReferenceManyField
		{...props}
		addLabel={false}
		source="id"
		reference="imastore/sessions"
		target={target}
		perPage={10000}
		sort={{ field: 'subject,id', order: 'ASC' }}
	>
		<SessionTable
			hideSubject={hideSubject}
		/>
	</ReferenceManyField>
);

const SessionTopFilter: React.FC = (props) => (
	<TopFilter {...props}>
		<SearchInput source="id" alwaysOn />
	</TopFilter>
);

const SessionPanelFilter: React.FC = (props) => (
	<AsideCard>
		<CardContent>
			<FilterLiveSearch source="subject" />
			<FilterList label="Scanner" icon={null} >
				<FilterListItem label="Siemens Prisma (FSL)" value={{
					manufacturer: "Siemens",
					model: "Prisma",
					institution: "FSL"
				}} />
				<FilterListItem label="Philips Achieva (FSL)" value={{
					manufacturer: "Philips",
					model: "Achieva",
					institution: "FSL"
				}} />
				<FilterListItem label="Philips Achieva (ITAB)" value={{
					manufacturer: "Philips",
					model: "Achieva",
					institution: "ITAB"
				}} />
				<FilterListItem label="Siemens Allegra (FSL)" value={{
					manufacturer: "Siemens",
					model: "Allegra",
					institution: "FSL"
				}} />
			</FilterList>
			<FilterList label="Visual projection" icon={null} >
				<FilterListItem label="standard" value={{
					visual_projection: "standard"
				}} />
				<FilterListItem label="widefield" value={{
					visual_projection: "widefield"
				}} />
				<FilterListItem label="binocular" value={{
					visual_projection: "binocular"
				}} />
			</FilterList>
		</CardContent>
	</AsideCard>
);

const SessionList: React.FC = (props) => {
	useAutoRefresh('imastore/sessions');
	return (
		<List
			bulkActionButtons={false}
			filters={<SessionTopFilter />}
			aside={<SessionPanelFilter />}
			sort={{ field: 'subject,id', order: 'ASC' }}
			{...props}
		>
			<SessionTable />
		</List>
	);
};

export default SessionList;
