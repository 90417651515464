import * as React from 'react';
import { Identifier, Record, useTranslate } from 'ra-core';
import { List, Responsive, SimpleList, Datagrid, TopToolbar } from 'ra-ui-materialui';
import { Button, CreateButton, EditButton, DeleteButton } from 'ra-ui-materialui';
import { TextField } from 'ra-ui-materialui';
import { Link } from 'ra-ui-materialui';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import Conditional from '../../../components/Conditional';
import ForDevelopers from '../../../components/ForDevelopers';
import useAutoRefresh from '../../../providers/useAutoRefresh';

const RunButton: React.FC<any> = (props) => {
	const translate = useTranslate();
	return (
		<Button
			{...props}
			component={Link}
			to={{
				pathname: `jobs/jobs/create`,
				search: `?pipeline=${props.record.id}`,
			}}
			label={translate("page.pipelines.run")}
		>
			<PlayArrowIcon />
		</Button>
	);
};

const ActionToolbar: React.FC<{
	[key: string]: any;
}> = (props) => (
	<TopToolbar>
		<ForDevelopers {...props}>
			<CreateButton undoable={true} />
		</ForDevelopers>
	</TopToolbar>
);

const PipelineList: React.FC = (props) => {
	useAutoRefresh('jobs/pipelines');
	return (
		<List
			title="Pipelines"
			bulkActionButtons={false}
			{...props}
			actions={<ActionToolbar />}
		>
			<Responsive
				small={
					<SimpleList
						primaryText={(record: Record) => record.name}
						secondaryText={(record: Record) => record.description}
						linkType={(record: Record, id: Identifier) =>
							`/jobs/jobs/create?pipeline=${id}`}
					/>
				}
				medium={
					<Datagrid
						rowClick={(id: Identifier, basePath: string, record: {}) =>
							`/jobs/jobs/create?pipeline=${id}`}
					>
						<TextField source="name" />
						<TextField source="description" />
						<Conditional>
							<EditButton label="" />
						</Conditional>
						<Conditional>
							<DeleteButton undoable={true} />
						</Conditional>
						<RunButton />
					</Datagrid>
				}
			/>
		</List>
	);
};


export default PipelineList;
