import * as React from 'react';
import { Record } from 'react-admin';
import { List, Responsive, SimpleList, Datagrid, TopToolbar } from 'ra-ui-materialui';
import { TextField, NumberField } from 'ra-ui-materialui';

import StateField from '../StateField';
import useAutoRefresh from '../../../providers/useAutoRefresh';


const NodeList: React.FC = (props) => {
	useAutoRefresh('jobs/nodes');
	return (
		<List
			title="Computing nodes"
			actions={<TopToolbar />}
			bulkActionButtons={false}
			{...props}
		>
			<Responsive
				small={
					<SimpleList
						primaryText={(record: Record) => record.name}
						secondaryText={(record: Record) => record.status}
						tertiaryText={(record: Record) => "Role: " + record.role}
						linkType="show"
					/>
				}
				medium={
					<Datagrid
						rowClick="show"
					>
						<TextField source="name" />
						<TextField source="role" />
						<NumberField source="cpus" label="Number of CPUs" />
						<NumberField source="memory" label="Total memory (MB)" />
						<NumberField source="usedCpu" label="Used CPU" options={{ style: "percent" }} />
						<NumberField source="usedMemory" label="Used memory (MB)" />
						<StateField label="Status" source="status" showValue />
					</Datagrid>
				}
			/>
		</List>
	);
};


export default NodeList;
