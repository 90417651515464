import * as React from 'react';

import { TextField, NumberField, ReferenceField } from 'ra-ui-materialui';
import { useRecordContext } from 'ra-core';

export const LiveNodeField: React.FC<any> = (props) => {
	const record = useRecordContext(props);
	return (
		record?.status === "running" ? (
			<ReferenceField
				source="node"
				reference="jobs/nodes"
				link="show"
			>
				<TextField label="Node" source="name" />
			</ReferenceField>
		) : null
	);
}

export const LiveNumberField: React.FC<any> = (props) => {
	const record = useRecordContext(props);
	return (
		record?.status === "running" ? (
			<NumberField {...props} />
		) : null
	);
}
