import * as React from 'react';
import { useLocation, useHistory } from "react-router-dom";

import { useTranslate } from 'ra-core';
import { Login } from 'ra-ui-materialui';
import { makeStyles } from '@material-ui/styles';
import { LoginPageContents } from './LoginPageTemplate';
import { defaultTheme } from '../../themes';
import { GoogleLoginButton } from '../../components/AuthButtons';
import BilServerProvider from '../../providers/BilServerProvider';

const useStyles = makeStyles({
	main: {
		backgroundColor: '#282c34'
	}
});

const LoginPage: React.FC = () => {
	const classes = useStyles();
	const translate = useTranslate();
	return (
		<Login backgroundImage="./logo.svg"
			theme={defaultTheme()}
			className={classes.main}>
			<LoginPageContents
				header1={translate("auth.loginPage.message1")}
				header2={translate("auth.loginPage.message2")}
			>
				<GoogleLoginButton />
			</LoginPageContents>
		</Login>
	);
}

const LoginManager: React.FC = () => {
	const location = useLocation();
	const history = useHistory();
	if (BilServerProvider.AuthProvider.loginCallback(location, history))
		return null;
	return (<LoginPage />);
}

export default LoginManager;
