import * as Docker from "./docker";
import { TaskSpec } from './references';

// PARAMETERS

export interface Parameter {
	name: string,
	description: string,
	type: ParameterType,
	choices: Choice[];
}

export type ParameterType = "string" | "number" | "choice";

export interface Choice {
	name: string,
	code: string;
}

// TEMPLATES

interface BaseTemplate {
	readonly id: string;
	readonly owner: string;
	readonly name: string;
	readonly description: string;
}

interface BaseTask extends BaseTemplate {
	readonly parameters: Parameter[];
}

export type EnvironmentVariable = Docker.EnvironmentVariable;
export type Volume = Docker.Volume;

export interface TaskUnit extends BaseTask, Docker.ServiceSpec {
}

export type ExecutionMode = "parallel" | "sequential";

export interface TaskGroup extends BaseTask {
	mode: ExecutionMode;
	tasks: TaskSpec[];
}

export type Task = TaskUnit | TaskGroup;

export interface Pipeline extends BaseTemplate {
	readonly autoDelete: boolean;
	readonly template: string;
}

export function isTaskUnit(task: Task): task is TaskUnit {
	return (task as TaskUnit).image !== undefined;
}

export function isTaskGroup(task: Task): task is TaskGroup {
	return (task as TaskGroup).tasks !== undefined;
}
