import * as React from 'react';

import { packageManager, MenuSection } from '../Package';
import useUser from '../providers/useUser';
import Menu from './Menu';
import { User } from '../providers/User.model';


interface MenuProps {
	condensed?: boolean
	userMenu?: boolean
}

function getUserMenuSection(user: User | null, sections: MenuSection[]): MenuSection {
	const firstSection = sections.shift() as MenuSection;
	return {
		id: firstSection.id,
		label: firstSection.label,
		items: firstSection.items.concat(packageManager.getUserMenuItems(user))
	}
}

const MainMenu: React.FC<MenuProps> = props => {
	const { condensed, userMenu } = props;
	const user = useUser();
	const menuSections = packageManager.getMainMenuItems(user);
	const userMenuItems = userMenu ? getUserMenuSection(user, menuSections) : null;

	return (
		<>
			{userMenuItems ? (
				<Menu condensed={condensed}
					sections={[userMenuItems]}
					divider={true}
				/>
			) : null}
			<Menu condensed={condensed}
				sections={menuSections}
				logout={userMenu && user != null}
				divider={true}
			/>
		</>
	);
};

export default MainMenu;
