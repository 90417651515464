import * as React from 'react';

import { Identifier } from 'react-admin';
import { List, Responsive, SimpleList, Datagrid } from 'ra-ui-materialui';
import { TextField, BooleanField, ReferenceManyField, ReferenceArrayField } from 'ra-ui-materialui';
import { FilterList, FilterListItem, SearchInput } from 'ra-ui-materialui';
import { CardContent } from '@material-ui/core';
import LinkedRecordField from '../../components/LinkedRecordField';
import useAutoRefresh from '../../providers/useAutoRefresh';
import TopFilter from '../../components/TopFilter';
import AsideCard from '../../components/AsideCard';
import FilterLiveSearch from '../../components/FilterLiveSearch';

export interface ScanListProperties {
	hideSubject?: boolean;
	hideSession?: boolean;
	hideTask?: boolean;
}

const ScanTable: React.FC<ScanListProperties> =
	({ hideSubject, hideSession, hideTask, ...props }) => (
		<Responsive {...props}
			small={
				<SimpleList
					primaryText={(record: any) => record && (record.subject + "-" + record.session + "-" + record.run)}
					secondaryText={(record: any) => record && (record.datatype + "-" + (record.modality ? record.modality : record.task))}
					linkType="show"
				/>
			}
			medium={
				<Datagrid rowClick={(id: Identifier) => `/imastore/scans/${id}/show`}>
					{!hideSubject && <LinkedRecordField source="subject" reference="imastore/subjects" />}
					{!hideSession && <LinkedRecordField source="session" reference="imastore/sessions" />}
					<TextField source="run" />
					{!hideTask && <TextField source="type" />}
					{!hideTask && <TextField source="modality" />}
					{!hideTask && <LinkedRecordField source="task" reference="imastore/tasks" />}
					<TextField source="acquisition" />
					<BooleanField source="preprocessed" />
				</Datagrid>
			}
		/>
	);

export const EmbeddedScanList: React.FC<ScanListProperties & {
	source?: string;
	target?: string;
}> = ({ source, target, hideSubject, hideSession, hideTask, ...props }) => (
	source !== undefined ?
		<ReferenceArrayField
			{...props}
			addLabel={false}
			source={source}
			reference="imastore/scans"
			perPage={10000}
		>
			<ScanTable
				hideSubject={hideSubject}
				hideSession={hideSession}
				hideTask={hideTask}
			/>
		</ReferenceArrayField>
		: target !== undefined ?
			<ReferenceManyField
				{...props}
				addLabel={false}
				source="id"
				reference="imastore/scans"
				target={target}
				perPage={10000}
				sort={{ field: 'subject,session,run', order: 'ASC' }}
			>
				<ScanTable
					hideSubject={hideSubject}
					hideSession={hideSession}
					hideTask={hideTask}
				/>
			</ReferenceManyField>
			: null
);

const ScanTopFilter: React.FC = (props) => (
	<TopFilter {...props}>
		<SearchInput source="id" alwaysOn />
	</TopFilter>
);

const ScanPanelFilter: React.FC = (props) => (
	<AsideCard>
		<CardContent>
			<FilterLiveSearch source="subject" />
			<FilterList label="Type" icon={null} >
				<FilterListItem label="anat" value={{
					type: "anat"
				}} />
				<FilterListItem label="func" value={{
					type: "func"
				}} />
			</FilterList>
			<FilterList label="Modality" icon={null} >
				<FilterListItem label="T1w" value={{
					modality: "T1w"
				}} />
				<FilterListItem label="T2w" value={{
					modality: "T2w"
				}} />
				<FilterListItem label="bold" value={{
					modality: "bold"
				}} />
			</FilterList>
		</CardContent>
	</AsideCard>
);

const ScanList: React.FC = (props) => {
	useAutoRefresh('imastore/scans');
	return (
		<List
			bulkActionButtons={false}
			filters={<ScanTopFilter />}
			aside={<ScanPanelFilter />}
			sort={{ field: 'subject,session,run', order: 'ASC' }}
			{...props}
		>
			<ScanTable />
		</List>
	);
};

export default ScanList;
