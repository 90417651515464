import * as React from 'react';
import { Show, TabbedShowLayout, Tab } from 'ra-ui-materialui';
import { EmbeddedScanList } from './ScanList';
import PropertyList from '../../components/PropertyList';

const SessionShow: React.FunctionComponent = (props) => (
	<Show
		{...props}
	>
		<TabbedShowLayout>
			<Tab label="Properties">
				<PropertyList />
			</Tab>
			<Tab label="Scans">
				<EmbeddedScanList {...props} target="session" hideSubject hideSession />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default SessionShow;
