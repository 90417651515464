import HomePackage from './home';
import ImaStorePackage from './imastore';
import JobsPackage from './jobs';
//import DocsPackage from './docs';
import AuthPackage from './auth';
import SettingsPackage from './settings';

function definePackage() {
	return {
		home: HomePackage(),
		imastore: ImaStorePackage(),
		jobs: JobsPackage(),
		//docs: DocsPackage(),
		auth: AuthPackage(),
		settings: SettingsPackage()
	};
}

export default definePackage; 