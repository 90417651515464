import { ReactNode } from 'react';
import * as React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { Title } from 'ra-ui-materialui';

import parse from './parse';
import Toc from './Toc';
import { Theme } from '../../themes';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
	},
	tocDocument: {
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: `calc(100% - ${theme.toc.width}px)`,
		}
	},
	noTocDocument: {
		width: '100%'
	},
	document: {
		...theme.typography.body1,
		color: theme.palette.text.primary,
		wordBreak: 'break-word',
		'& .anchor-link': {
			marginTop: -96, // Offset for the anchor.
			position: 'absolute',
		},
		'& pre': {
			margin: theme.spacing(3, 'auto'),
			padding: theme.spacing(2),
			backgroundColor: '#272c34',
			direction: 'ltr',
			borderRadius: theme.shape.borderRadius,
			overflow: 'auto',
			WebkitOverflowScrolling: 'touch', // iOS momentum scrolling.
			maxWidth: 'calc(100vw - 32px)',
			[theme.breakpoints.up('md')]: {
				maxWidth: 'calc(100vw - 32px - 16px)',
			},
		},
		'& code': {
			lineHeight: 1.4,
			display: 'inline-block',
			fontFamily: 'Consolas, "Liberation Mono", Menlo, Courier, monospace',
			WebkitFontSmoothing: 'subpixel-antialiased',
			padding: '0 3px',
			color: theme.palette.text.primary,
			backgroundColor:
				theme.palette.type === 'light' ? 'rgba(255, 229, 100, 0.2)' : 'rgba(255, 229, 100, 0.2)',
			fontSize: 14,
			borderRadius: 2,
		},
		'& code[class*="language-"]': {
			backgroundColor: '#272c34',
			color: '#fff',
			// Avoid layout jump after hydration (style injected by prism)
			lineHeight: 1.5,
		},
		'& p code, & ul code, & pre code': {
			fontSize: 14,
		},
		'& .token.operator': {
			background: 'transparent',
		},
		'& h1': {
			...theme.typography.h3,
			fontSize: 40,
			margin: '16px 0',
		},
		'& h2': {
			...theme.typography.h4,
			fontSize: 30,
			margin: '40px 0 16px',
		},
		'& h3': {
			...theme.typography.h5,
			margin: '40px 0 16px',
		},
		'& h4': {
			...theme.typography.h6,
			margin: '32px 0 16px',
		},
		'& h5': {
			...theme.typography.h5,
			margin: '0 0 40px',
			fontWeight: 400,
		},
		'& p, & ul, & ol': {
			marginTop: 0,
			marginBottom: 16,
		},
		'& ul': {
			paddingLeft: 30,
		},
		'& h1, & h2, & h3, & h4': {
			'& code': {
				fontSize: 'inherit',
				lineHeight: 'inherit',
				// Remove scroll on small screens.
				wordBreak: 'break-all',
			},
			'& .anchor-link-style': {
				// To prevent the link to get the focus.
				display: 'none',
			},
			'&:hover .anchor-link-style': {
				display: 'inline-block',
				padding: '0 8px',
				color: theme.palette.text.secondary,
				'&:hover': {
					color: theme.palette.text.primary,
				},
				'& svg': {
					width: '0.7em',
					height: '0.7em',
					fill: 'currentColor',
				},
			},
		},
		'& table': {
			// Trade display table for scroll overflow
			display: 'block',
			wordBreak: 'normal',
			width: '100%',
			overflowX: 'auto',
			WebkitOverflowScrolling: 'touch', // iOS momentum scrolling.
			borderCollapse: 'collapse',
			marginBottom: '16px',
			borderSpacing: 0,
			overflow: 'hidden',
			'& .prop-name': {
				fontSize: 13,
				fontFamily: 'Consolas, "Liberation Mono", Menlo, monospace',
			},
			'& .required': {
				color: theme.palette.type === 'light' ? '#006500' : '#a5ffa5',
			},
			'& .prop-type': {
				fontSize: 13,
				fontFamily: 'Consolas, "Liberation Mono", Menlo, monospace',
				color: theme.palette.type === 'light' ? '#932981' : '#ffb6ec',
			},
			'& .prop-default': {
				fontSize: 13,
				fontFamily: 'Consolas, "Liberation Mono", Menlo, monospace',
				borderBottom: `1px dotted ${theme.palette.divider}`,
			},
		},
		'& td': {
			...theme.typography.body2,
			borderBottom: `1px solid ${theme.palette.divider}`,
			padding: 16,
			color: theme.palette.text.primary,
		},
		'& td code': {
			fontSize: 13,
			lineHeight: 1.6,
		},
		'& th': {
			fontSize: 14,
			lineHeight: theme.typography.pxToRem(24),
			fontWeight: theme.typography.fontWeightMedium,
			color: theme.palette.text.primary,
			whiteSpace: 'pre',
			borderBottom: `1px solid ${theme.palette.divider}`,
			padding: 16,
		},
		'& blockquote': {
			borderLeft: '5px solid #ffe564',
			backgroundColor: 'rgba(255,229,100,0.2)',
			padding: '4px 24px',
			margin: '24px 0',
			'& p': {
				marginTop: '16px',
			},
		},
		'& a, & a code': {
			// Style taken from the Link component
			color: theme.palette.secondary.main,
			textDecoration: 'none',
			'&:hover': {
				textDecoration: 'underline',
			},
		},
		'& img, video': {
			maxWidth: '100%',
		},
		'& hr': {
			height: 1,
			margin: theme.spacing(6, 0),
			border: 'none',
			flexShrink: 0,
			backgroundColor: theme.palette.divider,
		},
		'& kbd': {
			// Style taken from GitHub
			padding: '2px 5px',
			font: '11px Consolas,Liberation Mono,Menlo,monospace',
			lineHeight: '10px',
			color: '#444d56',
			verticalAlign: 'middle',
			backgroundColor: '#fafbfc',
			border: '1px solid #d1d5da',
			borderRadius: 3,
			boxShadow: 'inset 0 -1px 0 #d1d5da',
		},
	},
}));

function isString(node: ReactNode): node is string {
	return typeof (node) === 'string';
}

const Page: React.FunctionComponent<{
	title?: boolean
	toc?: boolean
}> = (props) => {
	const classes = useStyles();
	if (!isString(props.children))
		return null;

	const contents = parse(props.children);
	const hasToc = props.toc && contents.toc.length > 0;
	return (
		<div className={classes.root}>
			{props.title ?
				<Title key="title" title={": " + contents.title} />
				: null
			}
			< div className={clsx({
				[classes.document]: true,
				[classes.tocDocument]: hasToc,
				[classes.noTocDocument]: !hasToc
			})}>
				{contents.page}
			</div>
			{hasToc ?
				<Toc items={contents.toc} scrollElementId="main" />
				: null
			}
		</div>
	);
};

export default Page;

