import * as React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

import { MenuSection as MenuSectionDef } from '../Package';

import MenuSectionHeader from './MenuSectionHeader';
import MenuItem from './MenuItem';


const useStyles = makeStyles((theme: Theme) => ({
	divider: {
		marginTop: theme.spacing(2),
	}
}));

interface MenuSectionProps extends MenuSectionDef {
	condensed?: boolean
	divider?: boolean
}

const MenuSection: React.FC<MenuSectionProps> = props => {
	const { id, label, items, condensed, divider } = props;
	const classes = useStyles();
	return items ? (
		<React.Fragment key={id}>
			<MenuSectionHeader
				condensed={condensed}
				label={label}
			/>
			{items.map(item => (
				<MenuItem
					key={item.path}
					{...item}
					condensed={condensed}
				/>
			))}
			{divider ?
				<Divider className={classes.divider} />
				: null}
		</React.Fragment>
	) : null;
}

export default MenuSection;