import * as React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

import { useRecordContext } from "ra-core";

const styles = {
	img: {
		width: 36,
		height: 36
	},
	root: {}
};

const ListAvatar: React.FunctionComponent<{
	className?: string,
	classes?: Record<"root" | "img", string>
}> = ({ className, classes }) => {
	const record = useRecordContext();
	return (record && classes) ? (
		<Avatar
			src={record.picture}
			className={classNames(classes.root, classes.img, className)}
		/>
	) : null;
};

export default withStyles(styles)(ListAvatar);