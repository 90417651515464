import * as React from "react";
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useRecordContext } from "react-admin";
import Line from "../../components/Line";

const ParameterFields: React.FC<{
	addLabel?: boolean;
	label?: string;
	source: string; 
}> = (props) => {
	const record = useRecordContext(props);
	const parameters = get(record, props.source);
	const params = Object.keys(parameters);
	return params.length === 0 ? (
		<Line value="none" />
	) : (
			<>
				{params.map(param => (
					<Line title={param} value={parameters[param]} />
				))}
			</>
		);
};

// what? TypeScript looses the displayName if we don't set it explicitly
ParameterFields.displayName = 'ParameterFields';

ParameterFields.propTypes = {
	addLabel: PropTypes.bool,
	label: PropTypes.string,
	source: PropTypes.string.isRequired
};

ParameterFields.defaultProps = {
	addLabel: true,
	label: "Parameters"
};

export default ParameterFields;