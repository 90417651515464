import * as React from 'react';
import { unified, Plugin, Transformer } from 'unified';
import markdown from 'remark-parse';
import toc from "remark-toc";
import slug from 'remark-slug';
import remark2rehype from 'remark-rehype';
import rehype2react from 'rehype-react';
import { Root } from 'mdast';
import { Element, RootContent } from 'hast';

export interface MarkdownDoc {
	page: React.ReactElement;
	toc: TocItem[];
	title: string;
}

export interface TocItem {
	text: string;
	level: number;
	hash: string;
	children?: TocItem[];
}

const addFirstLevelHeading: Plugin<[], Root> = (): Transformer<Root> => {
	return (node: Root): Root => {
	if (node.children.length === 0 ||
		node.children[0].type !== 'heading' ||
		node.children[0].depth !== 1 ||
		!node.children[0].children)
		node.children.unshift({
			type: 'heading',
			depth: 1,
			children: [
				{ type: 'text', value: '' }
			]
		});
		return node;
	};
};

const processor = unified()
  .use(markdown)
  .use(slug)
  .use(addFirstLevelHeading)
  .use(toc)
  .use(remark2rehype)
  .use(rehype2react, {
    createElement: React.createElement,
    Fragment: React.Fragment,
  })
  .freeze();

function tocFromNode(root: Element, level: number = 2) {
	const toc: TocItem[] = [];
	root.children.forEach(node => {
		if (node.type === 'element' &&
			node.tagName === 'li' &&
			node.children.length > 0 &&
			node.children[0].type === 'element' &&
			node.children[0].tagName === 'a') {
			const aNode = node.children[0];
			if (aNode.children.length === 1 &&
				aNode.children[0].type === 'text' &&
				aNode.children[0].value &&
				aNode.properties) {
				const text = aNode.children[0].value as string;
				const hash = (aNode.properties as any).href;
				if (hash) {
					const item: TocItem = {
						text,
						level,
						hash: hash as string
					};
					if (node.children.length > 1 &&
						node.children[1].type === 'element' &&
						node.children[1].tagName === 'ul') {
						const ulNode = node.children[1];
						item.children = tocFromNode(ulNode, level + 1);
					}
					toc.push(item);
				}
			}
		}
	});
	return toc;
}

function tocFromAst(root: RootContent | undefined) {
	if (root && root.type === "element" &&
		root.children.length > 1) {
		const child = root.children[1];
		if (child.type === "element" &&
			child.children.length > 1) {
			const child2 = child.children[1];
			if (child2.type === 'element' &&
				child2.tagName === 'ul') {
				return tocFromNode(child2, 2);
			}
		}
	}
	return [];
}

function titleFromAst(root: RootContent | undefined): string {
	if (root && root.type === "element" &&
		root.children.length > 0) {
		const child = root.children[0];
		if (child.type === "text")
			return child.value;
	}
	return "";
}

function parse(text: string): MarkdownDoc {
	const ast = processor.runSync(processor.parse(text));
	const toc = tocFromAst(ast.children.shift());
	ast.children.shift();
	const title = titleFromAst(ast.children.shift());
	ast.children.shift();
	const page = processor.stringify(ast) as unknown as React.ReactElement;
	return {
		page,
		toc,
		title
	};
}

export default parse; 