import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslate, FormDataConsumer, required, regex } from 'ra-core';
import { Create, Edit, SimpleForm, TopToolbar } from 'ra-ui-materialui';
import { ListButton } from 'ra-ui-materialui';
import { TextInput, BooleanInput, SelectInput } from 'ra-ui-materialui';

import useGetList from '../../../providers/useGetList';
import { Template } from '../model';
import StaticField from '../../../components/StaticField';
import ConditionalDeleteButton from '../../../components/ConditionalDeleteButton';

const PipelineActions: React.FunctionComponent = () => (
	<TopToolbar>
		<ListButton />
		<ConditionalDeleteButton />
	</TopToolbar>
);

const validateRequired = required();
const validateId = [required(), regex(/^[a-z0-9]+$/, 'The ID must only contain lowercase letters and numbers')];

const PipelineForm: React.FC<any> = (props) => {
	const translate = useTranslate();
	const tooltip = (field: string) => translate("resources." + props.basePath.substring(1) + ".tooltips." + field);

	const templates = useGetList<Template>("jobs/templates");
	if (!templates.isComplete())
		return templates.value();
	if (templates.ids.length === 0)
		return <Redirect to="/jobs/templates" />;

	const templateChoices = templates.ids?.map(id => ({
		id,
		name: templates.data[id].name
	}));

	return (
		<SimpleForm warnWhenUnsavedChanges
			{...props}
			redirect="list"
			variant="standard"
			submitOnEnter={false}
		>
			<TextInput source="id"
				helperText={tooltip("id")}
				validate={validateId}
				fullWidth={true}
			/>
			<TextInput source="name"
				helperText="The name of the pipeline"
				validate={validateRequired}
				fullWidth={true}
			/>
			<TextInput source="description" multiline
				helperText="Extended description of the pipeline"
				validate={validateRequired}
				fullWidth={true}
			/>
			<BooleanInput source="autoDelete"
				helperText="Will jobs running this pipeline be automatically deleted when complete/failed?"
				validate={validateRequired}
				fullWidth={true}
			/>
			<SelectInput source="template"
				label="Template"
				helperText="The task template to be called"
				choices={templateChoices}
			/>
			<FormDataConsumer>
				{({ formData, ...rest }) =>
					<StaticField label="Description" value={formData.template ? templates.data[formData.template].description : ""} />
				}
			</FormDataConsumer>
		</SimpleForm>
	);
};

export const PipelineCreate: React.FC<any> = (props) => {
	return (
		<Create
			title={<span>Create pipeline</span>}
			undoable={false}
			{...props}
			actions={<PipelineActions />}
		>
			<PipelineForm />
		</Create>
	);
};

export const PipelineEdit: React.FC<any> = (props) => {
	return (
		<Edit
			title={<span>Edit pipeline</span>}
			undoable={false}
			{...props}
			actions={<PipelineActions />}
		>
			<PipelineForm />
		</Edit>
	);
};