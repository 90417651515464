import Package from '../../Package';

import NodeList from './nodes/NodeList';
import NodeShow from './nodes/NodeShow';
import JobList from './jobs/JobList';
import JobShow from './jobs/JobShow';
import JobCreate from './jobs/JobCreate';
import TaskShow from './tasks/TaskShow';
import PipelineList from './pipelines/PipelineList';
import { PipelineCreate, PipelineEdit } from './pipelines/PipelineCreateEdit';
import TemplateList from './templates/TemplateList';
import { TemplateCreate, TemplateEdit } from './templates/TemplateCreateEdit';

import WorkIcon from '@material-ui/icons/Work';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ComputerIcon from '@material-ui/icons/Computer';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import FileCopyIcon from '@material-ui/icons/FileCopy';

function definePackage() {

	const pkg = Package("jobs", {
		en: "Job Manager",
		it: "Gestione lavori"
	});

	pkg.defineResource({
		path: "jobs/jobs",
		name: {
			en: {
				name: "Running jobs",
				fields: {}
			},
			it: {
				name: "Lavori in corso",
				fields: {}
			}
		},
		accessibleTo: "researcher",
		icon: WorkIcon,
		list: JobList,
		show: JobShow,
		create: JobCreate
	});

	pkg.defineResource({
		path: "jobs/tasks",
		name: {
			en: {
				name: "Running tasks",
				fields: {}
			},
			it: {
				name: "Lavori in corso",
				fields: {}
			}
		},
		accessibleTo: "researcher",
		showInPackageMenu: false,
		icon: AssignmentIcon,
		show: TaskShow
	});

	pkg.defineResource({
		path: "jobs/nodes",
		name: {
			en: {
				name: "Nodes",
				fields: {}
			},
			it: {
				name: "Nodi",
				fields: {}
			}
		},
		accessibleTo: "researcher",
		icon: ComputerIcon,
		list: NodeList,
		show: NodeShow
	});

	pkg.defineResource({
		path: "jobs/pipelines",
		name: {
			en: {
				name: "Pipelines",
				fields: {
					"id": "ID",
					parameters: "Pipeline parameters",
				},
				tooltips: {
					"id": "The pipeline ID will appear in the URL",
				}
			},
			it: {
				name: "Pipeline",
				fields: {
					"id": "ID",
					"name": "Nome",
					"description": "Descrizione",
					parameters: "Parametri della pipeline",
				},
				tooltips: {
					"id": "L'ID della pipeline apparirà nell'URL",
				}
			}
		},
		accessibleTo: "researcher",
		icon: PlayCircleOutlineIcon,
		list: PipelineList,
		create: PipelineCreate,
		edit: PipelineEdit
	});

	pkg.defineResource({
		path: "jobs/templates",
		name: {
			en: {
				name: "Templates",
				fields: {
					parameters: "Pipeline parameters",
				},
				tooltips: {
					"id": "The pipeline ID will appear in the URL",
				}
			},
			it: {
				name: "Modelli",
				fields: {
					"id": "ID",
					"name": "Nome",
					"description": "Descrizione",
					parameters: "Parametri del modello",
				},
				tooltips: {
					"id": "L'ID del modello apparirà nell'URL",
				}
			}
		},
		accessibleTo: "researcher",
		icon: FileCopyIcon,
		list: TemplateList,
		create: TemplateCreate,
		edit: TemplateEdit
	});

	return pkg;
}

export default definePackage;