import * as React from 'react';
import { Create, Edit, SimpleForm, SimpleFormIterator, TopToolbar, ArrayInput } from 'ra-ui-materialui';
import { ListButton } from 'ra-ui-materialui';
import { TextInput } from 'ra-ui-materialui';
import { required, regex } from 'ra-core';

const UserActions: React.FunctionComponent<{
	data?: any;
}> = (props) => (
	<TopToolbar>
		<ListButton />
	</TopToolbar>
);

const validateEmail = [required(), regex(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/, 'Invalid email')];
const validateRole = [required(), regex(/^[a-z0-9]+$/, 'The role must only contain lowercase letters and numbers')];

const UserForm: React.FC = (props) => (
	<SimpleForm
		{...props}
		redirect="list"
		variant="standard"
		submitOnEnter={false}
		initialValues={{ roles: ["user"] }}
	>
		<TextInput source="email" type="email"
			validate={validateEmail}
			fullWidth={true}
		/>
		<ArrayInput source="roles"
		>
			<SimpleFormIterator>
				<TextInput source=""
					label="Role"
					format={(value: any) => value && value.length ? value : ""}
					validate={validateRole}
					fullWidth={true}
					initialValue="user"
				/>
			</SimpleFormIterator>
		</ArrayInput>
	</SimpleForm>
);

export const UserCreate: React.FC = (props) => {
	return (
		<Create
			title={<span>Create user</span>}
			{...props}
			actions={<UserActions />}
		>
			<UserForm />
		</Create>
	);
}

export const UserEdit: React.FC<any> = (props) => {
	return (
		<Edit
			title={<span>Edit user</span>}
			{...props}
			actions={<UserActions />}
		>
			<UserForm />
		</Edit>
	);
}
