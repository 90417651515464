import * as React from 'react';
import { useTranslate } from 'ra-core';
import { required, regex, FormDataConsumer } from 'ra-core';
import { Create, Edit, TabbedForm, FormTab, SimpleFormIterator, TopToolbar, ArrayInput } from 'ra-ui-materialui';
import { ListButton } from 'ra-ui-materialui';
import { TextInput, NumberInput, SelectInput } from 'ra-ui-materialui';

import useGetList, { GetListResults } from '../../../providers/useGetList';
import { Template, formatTemplate, parseTemplate } from '../model';
import parametersForm from '../ParametersForm';
import ConditionalDeleteButton from '../../../components/ConditionalDeleteButton';

const TemplateActions: React.FunctionComponent = () => (
	<TopToolbar>
		<ListButton />
		<ConditionalDeleteButton />
	</TopToolbar>
);

const validateRequired = required();
const validateId = [required(), regex(/^[a-z0-9]+$/, 'The ID must only contain lowercase letters and numbers')];
const validateParameterName = [required(), regex(/^[a-z0-9]+$/, 'The ID must only contain lowercase letters and numbers')];
const validateChoiceId = [required(), regex(/^[a-zA-Z0-9_]+$/, 'The ID must only contain letters, numbers, and underscores')];
const validateImageTag = [regex(/^[a-zA-Z0-9./:\-_]+$/, 'Invalid characters in image tag')];
const validateEnvName = [required(), regex(/^[a-zA-Z0-9\-_]+$/, 'Invalid characters in environmental variable name')];
const validateMacAddress = regex(/^[a-f0-9][a-f0-9]:[a-f0-9][a-f0-9]:[a-f0-9][a-f0-9]:[a-f0-9][a-f0-9]:[a-f0-9][a-f0-9]:[a-f0-9][a-f0-9]$/, 'Invalid MAC address');

const TaskPropertiesForm: React.FC<any> = ({ basePath }) => {
	const translate = useTranslate();
	const tooltip = (field: string) => translate("resources." + basePath.substring(1) + ".tooltips." + field);
	return (
		<>
			<TextInput source="id"
				helperText={tooltip("id")}
				validate={validateId}
				fullWidth={true}
			/>
			<TextInput source="name"
				helperText="The name of the task generated by this template (may include parameters)"
				validate={validateRequired}
				fullWidth={true}
			/>
			<TextInput source="description" multiline
				helperText="Extended description of the task (may include parameters)"
				validate={validateRequired}
				fullWidth={true}
			/>
			<ArrayInput source="parameters">
				<SimpleFormIterator>
					<TextInput source="name"
						label="Name"
						helperText="Name of the parameter"
						validate={validateParameterName}
						fullWidth={true}
					/>
					<TextInput source="description"
						label="Description"
						helperText="Full description of the parameter"
						validate={validateRequired}
						fullWidth={true}
					/>
					<SelectInput source="type" choices={[
						{ id: 'string', name: 'text' },
						{ id: 'number', name: 'number' },
						{ id: 'choice', name: 'choice between a set of values' }
					]}
						label="Type"
						helperText="Type of value the parameter will accept"
						validate={validateRequired}
						fullWidth={true}
					/>
					<FormDataConsumer>
						{({ formData,
							scopedFormData,
							getSource,
							...rest }) =>
							getSource && scopedFormData && scopedFormData.type === 'choice' && (
								<ArrayInput source={getSource('choices')}
									{...rest}
									label="Choices">
									<SimpleFormIterator>
										<TextInput source="code"
											label="Value"
											helperText="This will be used as the parameter value"
											validate={validateChoiceId}
										/>
										<TextInput source="name"
											label="Name"
											helperText="This will appear in the pipeline form"
											validate={validateRequired}
										/>
									</SimpleFormIterator>
								</ArrayInput>
							)}
					</FormDataConsumer>
				</SimpleFormIterator>
			</ArrayInput>
			<SelectInput source="templateType"
				choices={[
					{ id: 'unit', name: 'This template runs a single computing unit (Docker image)' },
					{ id: 'group', name: 'This templates runs a set of other templates' }
				]}
				label="Template type"
				helperText="Choose whether this template runs a computing unit or other templates"
				validate={validateRequired}
				fullWidth={true}
			/>
		</>
	);
};

const TaskUnitForm: React.FC<any> = (props) => (
	<>
		<TextInput source="image"
			label="Docker image"
			helperText="Tag of the Docker image used for this task"
			validate={validateImageTag}
			fullWidth={true}
		/>
		<ArrayInput source="command"
			label="Command argument(s) to be passed to the Docker image"
		>
			<SimpleFormIterator>
				<TextInput source=""
					format={(value: any) => value && value.length ? value : ""}
					validate={validateRequired}
					label=""
					fullWidth={false}
				/>
			</SimpleFormIterator>
		</ArrayInput>
		<ArrayInput source="env"
			label="Environmental variables"
		>
			<SimpleFormIterator>
				<TextInput source="name"
					label="Name"
					validate={validateEnvName}
					fullWidth={false}
				/>
				<TextInput source="value"
					label="Value"
					fullWidth={false}
				/>
			</SimpleFormIterator>
		</ArrayInput>
		<ArrayInput source="volumes"
			label="Volumes"
		>
			<SimpleFormIterator>
				<TextInput source="hostPath"
					label="Path on the host system"
					validate={validateRequired}
					fullWidth={false}
				/>
				<TextInput source="mountPoint"
					label="Mount point in Docker image"
					validate={validateRequired}
					fullWidth={false}
				/>
			</SimpleFormIterator>
		</ArrayInput>
		<ArrayInput source="secrets"
			label="Secrets"
		>
			<SimpleFormIterator>
				<TextInput source=""
					format={(value: any) => value && value.length ? value : ""}
					validate={validateRequired}
					label=""
					fullWidth={false}
				/>
			</SimpleFormIterator>
		</ArrayInput>
		<TextInput source="macAddress"
			label="Container MAC address (if needed)"
			validate={validateMacAddress}
			fullWidth={true}
		/>
		<NumberInput source="minCPUs"
			label="Minimum number of required CPUs"
			step={1}
			fullWidth={false}
		/>
		<NumberInput source="maxCPUs"
			label="Maximum number of needed CPUs"
			step={1}
			fullWidth={false}
		/>
		<NumberInput source="minMemory"
			label="Minimum required memory (MB)"
			step={64}
			fullWidth={false}
		/>
		<NumberInput source="maxMemory"
			label="Maximum needed memory (MB)"
			step={64}
			fullWidth={false}
		/>
	</>
);

const TaskGroupForm: React.FC<{
	templates: GetListResults<Template>;
}> = ({ templates }) => {
	const templateChoices = templates.ids?.map(id => ({
		id,
		name: templates.data[id].name
	}));
	return (
		<>
			<SelectInput source="mode"
				label="Execution mode"
				helperText="Whether the included tasks will be executed one at a time or in parallel"
				choices={[
					{ id: 'sequential', name: 'Run sequentially' },
					{ id: 'parallel', name: 'Run in parallel' },
				]}
			/>
			<ArrayInput source="tasks"
				label="Tasks"
			>
				<SimpleFormIterator>
					<SelectInput source="template"
						label="Template"
						helperText="The task template to be called"
						defaultValue={templateChoices[0].id}
						choices={templateChoices}
					/>
					<FormDataConsumer>
						{({ formData,
							scopedFormData,
							getSource,
							...rest }) => (
							getSource && scopedFormData && parametersForm(getSource('parameters'), templates.data[scopedFormData.template])
						)
						}
					</FormDataConsumer>
				</SimpleFormIterator>
			</ArrayInput>
		</>
	);
};

const TemplateForm: React.FC<any> = (props) => {
	const templates = useGetList<Template>("jobs/templates");
	if (!templates.isComplete())
		return templates.value();

	return (
		<TabbedForm warnWhenUnsavedChanges
			{...props}
			record={props.record && formatTemplate(props.record)}
			redirect="list"
			variant="standard"
			submitOnEnter={false}
		>
			<FormTab label="Properties">
				<TaskPropertiesForm />
			</FormTab>
			<FormTab label="Task definition">
				<FormDataConsumer>
					{({ formData }) => formData && (
						formData.templateType === 'unit' ? (
							<TaskUnitForm />
						) : (
							<TaskGroupForm templates={templates} />
						)
					)}
				</FormDataConsumer>
			</FormTab>
		</TabbedForm>
	);
};

export const TemplateCreate: React.FC<any> = (props) => {
	return (
		<Create
			title={<span>Create template</span>}
			undoable={false}
			{...props}
			actions={<TemplateActions />}
			transform={parseTemplate}
		>
			<TemplateForm />
		</Create>
	);
};

export const TemplateEdit: React.FC<any> = (props) => {
	return (
		<Edit
			title={<span>Edit template</span>}
			undoable={false}
			{...props}
			actions={<TemplateActions />}
		>
			<TemplateForm />
		</Edit>
	);
};