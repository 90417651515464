import { Theme, createAppTheme } from '.';

const theme: Theme = createAppTheme({
	palette: {
		type: 'light',
		background: {
			paper: '#eaeff1'
		},
		primary: {
			light: '#63ccff',
			main: '#009be5',
			dark: '#006db3',
		}
	},
	typography: {
		h5: {
			fontWeight: 500,
			fontSize: 26,
			letterSpacing: 0.5,
		},
	},
	shape: {
		borderRadius: 8,
	},
	props: {
		MuiTab: {
			disableRipple: true,
		},
	}
}, theme => ({
	MuiCard: {
		root: {
			[theme.breakpoints.down('sm')]: {
				backgroundColor: '#cccccc',
			}
		}
	},
	MuiDrawer: {
		paper: {
			backgroundColor: '#18202c',
		},
	},
	MuiMenu: {
		paper: {
			backgroundColor: '#18202c',
		},
	},
	MuiButton: {
		label: {
			textTransform: 'none',
		},
		contained: {
			boxShadow: 'none',
			'&:active': {
				boxShadow: 'none',
			},
		},
	},
	MuiTabs: {
		root: {
			marginLeft: theme.spacing(1),
		},
		indicator: {
			height: 3,
			borderTopLeftRadius: 3,
			borderTopRightRadius: 3,
			backgroundColor: theme.palette.common.white,
		},
	},
	MuiTab: {
		root: {
			textTransform: 'none',
			margin: '0 16px',
			minWidth: 0,
			padding: 0,
			[theme.breakpoints.up('md')]: {
				padding: 0,
				minWidth: 0,
			},
		},
	},
	MuiTooltip: {
		tooltip: {
			borderRadius: 4,
		},
	},
	MuiDivider: {
		root: {
			backgroundColor: '#404854',
		},
	},
	MuiListItem: {
		root: {
			color: theme.palette.common.white
		},
		button: {
			color: 'rgba(255, 255, 255, 0.7)',
			'&:hover,&:focus': {
				backgroundColor: 'rgba(255, 255, 255, 0.08)',
			},
		}
	},
	MuiListItemText: {
		primary: {
			fontWeight: 500 //theme.typography.fontWeightMedium,
		}
	},
	MuiListItemIcon: {
		root: {
			color: 'rgba(255, 255, 255, 0.7)',
			marginRight: 0,
		},
	}
}));

export default theme;