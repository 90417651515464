import * as React from "react";
import PropTypes from 'prop-types';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { Link as RouterLink } from 'react-router-dom';
import { FieldTitle, Record, useRecordContext, useResourceContext } from 'ra-core';
import { Typography, TypographyProps, Link } from '@material-ui/core';

interface FieldProps {
	hideTitle?: boolean;
	addLabel?: boolean;
	source?: string;
	label?: string;
	className?: string;
}

const sanitizeProps = (props: object): object =>
	omit(props, [
		'addLabel',
		'allowEmpty',
		'basePath',
		'cellClassName',
		'className',
		'emptyText',
		'formClassName',
		'headerClassName',
		'label',
		'linkType',
		'link',
		'locale',
		'record',
		'resource',
		'sortable',
		'sortBy',
		'source',
		'textAlign',
		'translateChoice',
	]);

const SimpleField: React.FC<FieldProps & TypographyProps & {
	record?: Record
}> =
	({ source = "", label, className, hideTitle, ...rest }) => {
		const resource = useResourceContext();
		const record = useRecordContext(rest);
		const value = get(record, source);
		const props = sanitizeProps(rest);
		return (
			<div key={source}>
				{!hideTitle &&
					<Typography
						component="span"
						variant="subtitle2"
						{...props}
					>
						<FieldTitle
							label={label}
							source={source}
							resource={resource}
						/>
						{":\u00a0"}
					</Typography>
				}
				{["subject", "session", "task", "scan"].includes(source) ? (
					<Link component={RouterLink}
						to={`/imastore/${source}s/${value}/show`}
						className={className}
						{...props}
						onClick={(event: any) => event.stopPropagation()}
					>
						{value != null && typeof value !== 'string'
							? JSON.stringify(value)
							: value || ""}
					</Link>
				) : (
						<Typography
							component="span"
							variant="body2"
							className={className}
							{...props}
						>
							{value != null && typeof value !== 'string'
								? JSON.stringify(value)
								: value || ""}
						</Typography>
					)}
			</div>
		);
	};

// wat? TypeScript looses the displayName if we don't set it explicitly
SimpleField.displayName = 'SimpleField';

SimpleField.propTypes = {
	hideTitle: PropTypes.bool,
	addLabel: PropTypes.bool,
	label: PropTypes.string
};

SimpleField.defaultProps = {
	addLabel: false
};

export default SimpleField;
