import { useEffect, useState } from 'react';
import * as React from 'react';
import { useDataProvider, Record, RecordMap, PaginationPayload, SortPayload } from 'react-admin';
import { Loading } from 'ra-ui-materialui';

class GetListReturnValue<T extends Record = Record> {
	private readonly error: any;
	private readonly complete: boolean;

	constructor(error?: any, complete?: boolean) {
		this.error = error;
		this.complete = !!complete;
	}

	isComplete(): this is GetListResults<T> {
		return this.complete;
	}

	value(): any {
		if (this.error)
			return this.error;
		if (!this.complete)
			return (<Loading />);
		return this;
	}
}

export class GetListResults<T extends Record = Record> extends GetListReturnValue<T> {
	readonly data: RecordMap<T> = {};
	readonly ids: React.ReactText[] = [];
	readonly total: number;

	constructor(data: T[], total: number) {
		super(false, true);
		this.data = data.reduce((map, obj) => {
			map[obj.id] = obj;
			return map;
		}, {} as RecordMap<T>);
		this.ids = data.map(record => record.id);
		this.total = total;
	}
}

export default function useGetList<T extends Record = Record>(
	resource: string,
	pagination?: PaginationPayload,
	sort?: SortPayload,
	filter?: any
): GetListReturnValue<T> {
	const dataProvider = useDataProvider();
	const [data, setData] = useState<T[]>([]);
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<any>();
	useEffect(() => {
		dataProvider.getList(resource, {
			pagination: pagination ?? {
				page: 1,
				perPage: 10000
			},
			sort: sort ?? {
				field: 'id',
				order: 'ASC'
			},
			filter: filter ?? {}
		}).then(({ data, total }) => {
			setData(data as T[]);
			setTotal(total);
			setLoading(false);
		}).catch(error => {
			setError(error);
			setLoading(false);
		});
	}, [dataProvider, filter, pagination, resource, sort]);
	if (loading)
		return new GetListReturnValue<T>();
	if (error)
		return new GetListReturnValue<T>(error);
	return new GetListResults<T>(data, total);
}