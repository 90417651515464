import { Fragment } from 'react';
import * as React from 'react';
import classnames from 'classnames';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import {
	MuiThemeProvider,
	createTheme,
	withStyles,
	createStyles,
	Theme
} from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';

import { defaultTheme } from 'ra-ui-materialui';

const styles = (theme: Theme) =>
	createStyles({
		main: {
			display: 'flex',
			flexDirection: 'column',
			minHeight: '100vh',
			height: '1px',
			alignItems: 'center',
			justifyContent: 'flex-start',
			backgroundImage: 'url(/logo.svg)',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			backgroundColor: '#282c34'
		},
		card: {
			minWidth: 300,
			marginTop: '6em',
		},
		avatar: {
			margin: '1em',
			display: 'flex',
			justifyContent: 'center',
		},
		icon: {
			backgroundColor: theme.palette.secondary.dark
		},
	});

/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider` using the AUTH_LOGIN verb. Redirects to the root page
 * (/) upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 */

const theme = createTheme(defaultTheme);

export const LoginPageContents: React.FC<{
	header1: string;
	header2: string
}> = (props) => (
	<Fragment>
		<CardContent>
			<Typography variant="body1" gutterBottom>
				{props.header1}<br />{props.header2}
			</Typography>
		</CardContent>
		<CardActions style={{ justifyContent: "center" }}>
			{props.children}
		</CardActions>
	</Fragment>
);

const LoginPage: React.FC<{
	classes: { [key: string]: string };
	className: string;
	header1: string;
	header2: string
}> = ({ classes, className, children, header1, header2 }) => (
	<MuiThemeProvider theme={theme}>
		<div
			className={classnames(classes.main, className)}
		>
			<Card className={classes.card}>
				<div className={classes.avatar}>
					<Avatar className={classes.icon}>
						<LockIcon />
					</Avatar>
				</div>
				<LoginPageContents header1={header1} header2={header2}>
					{children}
				</LoginPageContents>
			</Card>
		</div>
	</MuiThemeProvider >
);

export default withStyles(styles)(LoginPage) as React.FC<{
	header1: string;
	header2: string
}>;
