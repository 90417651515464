import * as React from 'react';
import { Record, useGetOne, useRecordContext } from 'ra-core';
import { Show, TabbedShowLayout, Tab } from 'ra-ui-materialui';
import { EmbeddedDerivativeList } from './DerivativeList';
import PropertyList from '../../components/PropertyList';
import DownloadButton from './DownloadButton';

const DerivativeDownload: React.FunctionComponent<{
	original?: Record;
	derivative?: Record;
}> = ({ original, derivative, ...props }) => (
	<>
		{derivative?.class === 'surface' ? (
			<DownloadButton record={derivative} resource="derivatives" source="image">
				Download surface
			</DownloadButton>
		) : derivative?.class === 'table' ? (
			<DownloadButton record={derivative} resource="derivatives" source="table">
				Download table
			</DownloadButton>
		) : (
			<>
				<DownloadButton record={derivative} resource="derivatives" source="image">
					Download image
				</DownloadButton>
				<DownloadButton record={derivative} resource="derivatives" source="sidecar">
					Download sidecar
				</DownloadButton>
			</>
		)}
		<DownloadButton record={original} resource="scans" source="image">
			Download original image
		</DownloadButton>
		<DownloadButton record={original} resource="scans" source="sidecar">
			Download original sidecar
		</DownloadButton>
	</>
);

const OriginalProperties: React.FunctionComponent<{
	data?: Record;
}> = ({ data }) => (
	<PropertyList record={data} omit={["derivatives"]} />
);

const OtherDerivatives: React.FunctionComponent<{
	data?: Record;
}> = ({ data }) => (
	<EmbeddedDerivativeList
		record={data}
		target="original"
		singleScan
	/>
);

const DerivativeLayout: React.FunctionComponent = (props) => {
	const record = useRecordContext();
	const isScan = record?.session !== undefined;
	const { data, loaded } = useGetOne(isScan ? "imastore/scans" : "imastore/subjects", record?.original);
	if (!loaded)
		return null;

	return (
		<TabbedShowLayout {...props}>
			<Tab label="Properties">
				<PropertyList omit={["original"]} />
			</Tab>
			<Tab label="Original">
				<OriginalProperties data={data} />
			</Tab>
			{isScan &&
				<Tab label="Other derivatives">
					<OtherDerivatives data={data} />
				</Tab>
			}
			<Tab label="Download">
				<DerivativeDownload original={data} derivative={record} />
			</Tab>
		</TabbedShowLayout>
	);
};

const DerivativeShow: React.FunctionComponent = (props) => (
	<Show {...props}>
		<DerivativeLayout />
	</Show >
);

export default DerivativeShow;
