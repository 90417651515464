import * as React from 'react';
import { Record, usePermissions } from 'ra-core';
import { Button, makeStyles } from '@material-ui/core';

import { User } from '../../providers/User.model';

async function downloadData(record: Record, resource: string, source: string, user: User) {
	const url = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}imastore/${resource}/${record.id}?download=${source}`;
	window.location.href = url;
}

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));

const DownloadButton: React.FC<{
	record?: Record;
	resource: string;
	source: string;
}> = ({ record, resource, source, ...props }) => {
	const classes = useStyles();
	const { loaded, permissions } = usePermissions();
	return record && record[source] && loaded ? (
		<div className={classes.root}>
			<Button variant="contained" color="primary" {...props}
				onClick={() => downloadData(record, resource, source, permissions)}
			>
			</Button>
		</div>
	) : null;
};

export default DownloadButton;