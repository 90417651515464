import * as React from 'react';
import { Record, useRecordContext } from 'react-admin';
import { List, Responsive, SimpleList, Datagrid, TopToolbar } from 'ra-ui-materialui';
import { TextField, ReferenceField, NumberField } from 'ra-ui-materialui';

import StateField from '../StateField';
import OwnerField from '../OwnerField';
import Line from '../../../components/Line';
import { LiveNumberField, LiveNodeField } from '../LiveFields';

import useAutoRefresh from '../../../providers/useAutoRefresh';


const TaskExpandPanel: React.FC = () => {
	const record = useRecordContext();
	return (
		<>
			<Line title="Description" value={record!.description} />
			{record!.template && <Line title="Template" value={record!.template} />}
			{Object.keys(record!.parameters).map(key =>
				<Line key={key} title={key} value={record!.parameters[key]} />
			)}
		</>
	);
}

export const TaskTable: React.FC<{
	showJob?: boolean;
	showOwner?: boolean;
	showNode?: boolean;
	showStatus?: boolean;
}> = ({ showJob, showOwner, showNode, showStatus }, ...props) => {
	useAutoRefresh('jobs/tasks');
	return (
		<Responsive
			small={
				<SimpleList
					primaryText={(record: Record) => record.name}
					secondaryText={(record: Record) => record.description}
					tertiaryText={(record: Record) => record.status}
					linkType="show"
				/>
			}
			medium={
				<Datagrid
					{...props}
					rowClick="show"
					expand={<TaskExpandPanel />}
				>
					{showOwner && <OwnerField />}
					{showJob && <ReferenceField label="Job" source="job" reference="jobs/jobs" link="show">
						<TextField source="name" />
					</ReferenceField>}
					<TextField label="Step" source="label" />
					<TextField label="Task" source="name" />
					{showNode && <LiveNodeField label="Node" />}
					<TextField label="Started" source="startedAt" sortBy="started" />
					<NumberField label="Run for" source="lasted" sortBy="duration" />
					<LiveNumberField label="CPU" source="cpu" options={{ style: "percent" }} />
					<LiveNumberField label="Memory" source="memory" />
					<StateField label="Status" source="status" showValue={showStatus} />
				</Datagrid>
			}
		/>
	);
};

const TaskList: React.FC = (props) => {
	useAutoRefresh('jobs/tasks');
	return (
		<List
			title="Running tasks"
			actions={<TopToolbar />}
			bulkActionButtons={false}
			sort={{
				field: 'job',
				order: 'ASC'
			}}
			{...props}
		>
			<TaskTable showJob showOwner showNode showStatus />
		</List>
	);
};


export default TaskList;
