import { Typography } from "@material-ui/core";
import { useParams } from "react-router";


// Generic precompiled Google form

type GoogleFormField = [number, string | Date];

function precompiledGoogleForm(formId: string, fields: GoogleFormField[]) {
	const url = "https://docs.google.com/forms/d/e/" + formId + "/formResponse";
	const query = fields.map(field => {
		const name = "entry." + field[0].toString();
		const value = isDate(field[1]) ? formatDate(field[1]) : field[1];
		return [name, encodeURIComponent(value)].join("=");
	}).join("&");
	return [url, query].join("?");
}

function formatDate(value: Date) {
	const year = value.getFullYear().toString();
	let month = toTwoDigitString(value.getMonth() + 1);
	const day = toTwoDigitString(value.getDate());
	return [year, month, day].join("-");
}

function toTwoDigitString(value: number) {
	let str = value.toString();
	if (str.length === 1)
		str = "0" + str;
	return str;
}

function isDate(value: string | Date): value is Date {
	return (value as Date).getDay !== undefined;
}


// Autocertificazione Google form

function autocertificazioneUrl (nome: string, cognome: string) {
	return precompiledGoogleForm("1FAIpQLSdAG_KegcP-AIyDqb875yaFw3dd9xn8k65zDTOXsjtIoLTFKQ", [
		[1744344743, nome],
		[591194872, cognome],
		[1151417888, "Dipartimento di Psicologia"],
		[1519107157, new Date()],
		[1138800052, "tutto il giorno"],
		[584802252, "RM024 - Psicologia - Via dei Marsi, 78 (Via degli Apuli,4)"],
		[1213789504, "Attività didattica e di ricerca"],
		[337978257, "Dichiaro di essere autorizzato all'accesso in Ateneo ed all'effettuazione delle attività sopra descritte, secondo quanto previsto dai regolamenti, dalle circolari e dalle altre disposizioni di Ateneo"],
		[777919198, "Acconsento"]
	]);
}


// Autocertificazione web page

type AutocertificazioneParams = {
	nome: string;
	cognome: string;
};

const Autocertificazione: React.FC = () => {
	const { nome, cognome } = useParams<AutocertificazioneParams>();
	const url = autocertificazioneUrl(nome, cognome);
	window.location.href = url;
	return (<Typography>
		`Redirecting to "{url}"`
	</Typography>);
};

export default Autocertificazione;
