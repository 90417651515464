import * as React from 'react';
import PropTypes from 'prop-types';
import { Record, useRecordContext } from 'react-admin';
import { ReferenceField, FunctionField } from 'ra-ui-materialui';

import Avatar from "@material-ui/core/Avatar";

const OwnerField: React.FunctionComponent<{
	showName?: boolean;
	addLabel?: boolean;
	label?: string;
	source?: string;
}> = (props) => {
	const record = useRecordContext(props);
	return record ? (
		record.owner === "admin" ? (
			<>admin</>
		) : (
			<ReferenceField {...props}
				source="owner"
				reference="auth/users"
				link="show"
			>
				<FunctionField render={(user?: Record) => (
					<>
						<Avatar src={user?.picture} component="span" />
						{props.showName && user?.name}
					</>
				)}
				/>
			</ReferenceField>
		)
	) : null;
}

OwnerField.propTypes = {
	addLabel: PropTypes.bool,
	label: PropTypes.string,
	source: PropTypes.string
};

OwnerField.defaultProps = {
	addLabel: true,
	label: "User"
};

export default OwnerField;