import { useCallback } from 'react';
import * as React from 'react';
import { useLogout } from 'ra-core';

import LogoutIcon from '@material-ui/icons/PowerSettingsNew';

import { MenuItem as MenuItemDef, MenuSection as MenuSectionDef } from '../Package';
import MenuSection from './MenuSection';


const LogoutSection: React.FC<{
	condensed?: boolean
	divider?: boolean
	redirectTo?: string
}> = props => {
	const { redirectTo, ...other } = props;
	const logout = useLogout();
	const handleClick = useCallback(() => logout(redirectTo), [
		redirectTo,
		logout,
	]);

	return (
		<MenuSection {...props}
			id="logout"
			items={[{
				path: "",
				label: "ra.auth.logout",
				icon: LogoutIcon,
				action: handleClick
			}]}
			{...other}
		/>
	);
}

interface MenuProps {
	condensed?: boolean
	sections?: MenuSectionDef[]
	items?: MenuItemDef[]
	divider?: boolean
	logout?: boolean
}

const Menu: React.FC<MenuProps> = props => {
	const { sections, items, logout, condensed, divider } = props;

	return (
		<>
			{(sections && sections.length > 0) ?
				sections.map((section, index) => (
					<MenuSection
						key={section.id}
						{...section}
						condensed={condensed}
						divider={divider || logout || index < sections.length - 1}
					/>
				)) : (items && items.length > 0) ? (
					<>
						<MenuSection
							id=""
							items={items}
							condensed={condensed}
							divider={divider || logout}
						/>
					</>
				) : null
			}
			{logout ? (
				<LogoutSection
					condensed={props.condensed}
					divider={divider}
				/>
			) : null}
		</>
	);
};

export default Menu;
