import { required } from 'ra-core';
import { TextInput, NumberInput, SelectInput } from 'ra-ui-materialui';
import { Template, Parameter } from './model';

const ParameterInput: React.FC<{
	source: string;
	parameter: Parameter;
}> = ({ source, parameter, ...props }) => {
	const choices = parameter.choices.map(choice => ({
		id: choice.code,
		name: choice.name
	}));
	const properties = {
		...props,
		source: source + "." + parameter.name,
		label: parameter.name,
		helperText: parameter.description,
		validate: required(),
		fullWidth: false
	};
	return (parameter.type === "string") ?
		<TextInput {...properties} /> :
		(parameter.type === "number") ?
			<NumberInput {...properties} /> :
			<SelectInput  {...properties} choices={choices} />;
};

export default function parametersForm(source: string, template: Template): JSX.Element[] {
	return template.parameters.map((parameter) => (
		<ParameterInput key={parameter.name} source={source} parameter={parameter} />
	));
}
